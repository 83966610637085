<template>
  <!--  &lt;!&ndash; 案例介绍    &ndash;&gt;-->
  <div  style="  background: rgba(0, 0, 0, 1); "  :style="getStyleByBodyWd(1,1)"   >
    <!--    案例详情页面-->
    <div v-if="case_detail>=0&&locator==4&&!isCaseTransition">
      <!--  type=0,大图浏览 -->
      <div v-if="caseShow[case_detail].type==0"  :style="getStyleByBodyWd(1,1)" style="justify-content: right;">
        <div  :style="getStyleByBodyWd(1,0)" style="display: flex;justify-content: right;">
          <div   style="position: absolute; z-index: 999;justify-content: right;"  >
            <div  :style="getStyleByBodyWd(1,0)"  style="opacity: 0">
            </div>
            <div :style="getStyleByBodyWd(1,0)" style="display: flex;justify-content: space-between;">
              <div style="opacity: 0"  >
                <img  :class="windowTurn?'detail_pic_close_icon_turn':'detail_pic_close_icon'" src="../assets/case_catalog/detail_pic_close_circle.svg">
              </div>
              <div  @click="closeCaseDetail" style="margin:10px;">
                <img  :class="windowTurn?'detail_pic_close_icon_turn':'detail_pic_close_icon'" src="../assets/case_catalog/detail_pic_close_circle.svg">
              </div>

            </div>
          </div>
        </div>

        <el-scrollbar  :style="getStyleByBodyWd(1,0.9)">
          <img :src="caseShow[case_detail].src"  style="width: 100%">
        </el-scrollbar>
      </div>
      <!-- type=1/3,视频播放/图片-->
      <div   v-if="caseShow[case_detail].type==1||caseShow[case_detail].type==3" :class="windowTurn?'case_detail_padding_turn':'case_detail_padding'"   style="display: flex;justify-content: center;align-items: center">
        <div :style="getStyleByBodyWd(13.2,1.19)" style="display: flex;flex-direction: column;justify-content: space-around;align-items: center">
          <div  v-if="catalogSelect==6" class="case_title_grad"  :class= "windowTurn?'case_left_title_1_turn '+caseTitleGradBottom[catalogSelect]:'case_left_title_1 '+ caseTitleGradBottom[catalogSelect]" style="writing-mode:vertical-rl;">  {{caseShow[case_detail].title_y}}</div>
          <div  v-else class="case_title_grad"  :class= "windowTurn?'case_left_title_1_turn '+caseTitleGradBottom[catalogSelect]:'case_left_title_1 '+ caseTitleGradBottom[catalogSelect]" style="writing-mode:vertical-rl;">
            <div v-if="caseShow[case_detail].title_y !=null && caseShow[case_detail].title_y != undefined">{{caseShow[case_detail].title_y}}</div>
            <div v-else>
              {{caseShow[case_detail].title.substring(0,8)}}
            </div>
            </div>
          <div :class="windowTurn?'detail_info_text_turn':'detail_info_text'"  class="font_family_sy_balck" style="writing-mode:vertical-rl;">服务内容 : {{caseShow[case_detail].info}}</div>
        </div>
        <video-show  :s_url="caseShow[case_detail].src" :pre_src="caseShow[case_detail].preview"  v-if="caseShow[case_detail].type==1" :style="getStyleByBodyWd(1.19,1.19)"  ></video-show>
        <!--              <video   v-if="caseShow[case_detail].type==1"   :poster="caseShow[case_detail].preview" preload="auto"  :autoplay="true" :style="getStyleByBodyWd(1.19,1.19)"   class="video_item  container_border_debug"  v-bind:src="caseShow[case_detail].src" controls style="object-fit: contain; border-radius: 10px;" ></video>-->

        <div v-if="caseShow[case_detail].type==3"  class="video_item   "   :style="getStyleByBodyWd(1.19,1.19)"  >
          <img     :src="caseShow[case_detail].src"  style="height: 100%;">
        </div>
        <div :style="getStyleByBodyWd(13.2,1.19)" class="column_flex_between">
          <div @click="closeCaseDetail" >
            <img  :class="windowTurn?'detail_close_icon_turn':'detail_close_icon'" src="../assets/case_catalog/detail_page_close.svg">
          </div>
          <img :src="caseModels[catalogSelect].logo_y" :style="getStyleByBodyWd(0,8)">
        </div>
      </div>
      <!--type=2,图片轮播  -->
      <div   v-if="caseShow[case_detail].type==2" :class="windowTurn?'case_detail_padding_turn':'case_detail_padding'"   style="display: flex;justify-content: center;align-items: center">
        <!--左侧-->
        <div  :style="getStyleByBodyWd(13.2,1.15)" style="display: flex;flex-direction: column;justify-content: space-between;align-items: center">
          <div v-if="catalogSelect==6" class="case_title_grad "  :class= "windowTurn?'case_left_title_1_turn '+caseTitleGradBottom[catalogSelect]:'case_left_title_1 '+ caseTitleGradBottom[catalogSelect]" style="writing-mode:vertical-rl;">  {{caseShow[case_detail].title_y}}</div>
          <div v-else class="case_title_grad "  :class= "windowTurn?'case_left_title_1_turn '+caseTitleGradBottom[catalogSelect]:'case_left_title_1 '+ caseTitleGradBottom[catalogSelect]" style="writing-mode:vertical-rl;">
            <div v-if="caseShow[case_detail].title_y !=null && caseShow[case_detail].title_y != undefined">{{caseShow[case_detail].title_y}}</div>
            <div v-else>
              {{caseShow[case_detail].title.substring(0,8)}}
            </div>

          </div>
          <div   :class="windowTurn?'detail_info_text_turn':'detail_info_text'" class="font_family_sy_balck" style="writing-mode:vertical-rl;">服务内容 : {{caseShow[case_detail].info}}</div>
        </div>
        <!--中间轮播-->
        <el-carousel  indicator-position="none"  :initial-index="initialIndex"  :style="getStyleByBodyWd(1.2,1.15) "  ref="carousel"  interval="4000">
          <el-carousel-item v-for="(item,index) in caseShow[case_detail].src" :key="index" :style="getStyleByBodyWd(1.2,1.15)" class="column_flex_between">
            <div style="display: flex;justify-content: center;align-items: center; background: black" :style="caseShow[case_detail].src.length>1?getStyleByBodyWd(1.2,1.2):getStyleByBodyWd(1.2,1.15)">
              <img   :style="getStyleByBodyWd(0,1.2)"    :src="item"  style="height: 100%;"  >
            </div>
            <div  v-show="caseShow[case_detail].src.length>1" style="display: flex;justify-content: space-between;align-items: center;">
              <div   v-for=" (n,n_index) in caseShow[case_detail].src.length" :key="n"
                     :style="n_index==index?getStyleByBodyWd(1.2*caseShow[case_detail].src.length,250)+'background: rgba(255, 255, 255, 1);':getStyleByBodyWd(1.2*caseShow[case_detail].src.length,300)+'background: rgba(255, 255, 255, 0.3);'"
              ></div>
            </div>

          </el-carousel-item>

        </el-carousel>
        <!--右侧-->
        <div :style="getStyleByBodyWd(13.2,1.15)" class="column_flex_between " >
          <div @click="closeCaseDetail" >
            <img  :class="windowTurn?'detail_close_icon_turn':'detail_close_icon'" src="../assets/case_catalog/detail_page_close.svg">
          </div>
          <div :style="getStyleByBodyWd(0,5)" class="column_flex_between" v-show="caseShow[case_detail].src.length>1">
            <div @click="change_carousel('next')"><img  :style="getStyleByBodyWd(29,0)" src="../assets/case_catalog/next_button.svg"></div>
            <div @click="change_carousel('prev')"><img  :style="getStyleByBodyWd(29,0)" src="../assets/case_catalog/last_button.svg"></div>
          </div>
          <img :src="caseModels[catalogSelect].logo_y" :style="getStyleByBodyWd(0,8)">
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import VideoShow from "@/components/videoShow";
export default {
  components: {VideoShow},
  name: "caseDetails",
  data() {
    return {
      locator:4,
      catalogSelect:0,
      case_detail:-1,
      htmlWidth:document.getElementsByTagName('html')[0].clientWidth,
      htmlHeight:document.getElementsByTagName('html')[0].clientHeight,
      windowTurn:false,
      catalog_bg:['catalog_bg_img_0','catalog_bg_img_1','catalog_bg_img_2','catalog_bg_img_3','catalog_bg_img_4','catalog_bg_img_5','catalog_bg_img_1'],
      caseTitleGrad:['case_grad_color_0',"case_grad_color_1","case_grad_color_2","case_grad_color_3","case_grad_color_4","case_grad_color_5","case_grad_color_1"],
      caseTitleGradBottom:['case_grad_color_0_bottom',"case_grad_color_1_bottom","case_grad_color_2_bottom","case_grad_color_3_bottom","case_grad_color_4_bottom","case_grad_color_5_bottom","case_grad_color_1_bottom"],
      caseModels:this.$caseModels,
      caseShow:[],
      caseDetails: this.$caseDetails,
      videoCaseIndex:0,
      case_index:0,
      isLoading:false,
      isCaseTransition:false,
      initialIndex:0,
      //waterfall
      waterfallImgCol: 3,// 瀑布流的列数
      waterfallImgWidth: 0,// 每个盒子的宽度
      waterfallImgRight: 0,// 每个盒子的右padding
      waterfallImgBottom:0,// 每个盒子的下padding
      waterfallDeviationHeight: [],
      imgList: [],
      waterfallList: [],
      waterfall_key:0,
      waterfall_model:[
        {
          src:this.$mediaURL+'/case_2/cover/01.webp',
          width:0,
          height:0,
        },
        {
          src:this.$mediaURL+'/case_1/cover/02.webp',
          width:0,
          height:0,
        },
      ],
    }
  },
  mounted() {



    if(localStorage.getItem("catalogSelect")!=null && localStorage.getItem("case_detail_id")!=null)
    {
      this.catalogSelect=localStorage.getItem("catalogSelect");
      this.case_detail=  localStorage.getItem("case_detail_id");
      this.caseShow=this.caseDetails[this.catalogSelect];
    }else
    {

      for (var m=0;m<this.caseModels.length;m++)
      {
        var title = this.$route.params.title;

        if (title == this.caseModels[m].title_en.replace(/\s/g,"_"))
        {
          this.catalogSelect=m;
          break;
        }
      }
      this.caseShow=this.caseDetails[this.catalogSelect];
      for (var n=0;n<this.caseShow.length;n++)
      {
        var case_title = this.$route.params.case;

        if (case_title==this.caseShow[n].title_en)
        {

          this.case_detail=n;
          break;
        }
      }
    }

    this.init();
  },
  methods: {

    init() {
      this.forceLandscapeScreenHandle();
      // 这里监控
      this.onWindowSizeChanged();
    },
    getToPage(val){
      this.$router.push(val);
    },
    //图片预加载
    imgCoverPreloading() {
      for (let i = 0; i < this.caseDetails.length; i++) {

        let aImg = new Image();
        aImg.src =this.caseDetails[i][1].cover;
        aImg.onload = aImg.onerror = () => {
          let imgData = {};
          imgData.orheight=aImg.height;
          imgData.orwidth=aImg.width;
          imgData.height = this.waterfallImgWidth / aImg.width * aImg.height;
          imgData.src =this.caseDetails[i][1].cover;
          imgData.title = this.caseDetails[i][1].title;// 说明文字（也可以自己写数组，或者封装json数据，都可以，但是前提是你会相关操作，这里不赘述）

          imgData.id=i;

        }
      }



    },
    openCaseDetail(value) {
      var url=  '/services/'+this.caseModels[this.catalogSelect].title+'/'+this.caseDetails[this.catalogSelect][value].title;
      this.$router.push(url);
      this.case_detail=value;
      this.caseShow=this.caseDetails[this.catalogSelect];

      this.$forceUpdate();

    },
    closeCaseDetail() {
      // var url=  '/services/'+this.caseModels[this.catalogSelect].title_en.replace(/\s/g,"_");
      localStorage.removeItem("case_detail_id");
      // this.$router.push(url);
      this.$router.push({name:'waterfall',params:{user: this.case_detail}});


    },

    change_carousel(ty){
      this.$refs['carousel'][ty]()
    },

    subString_title(S,line)
    {
      var str1=S.substring(0, S.indexOf(" "));
      var str2=S.substring(str1.length+1, S.length);
      if(line==1) return str1;
      if(line==2) return  str2;
    },

    forceLandscapeScreenHandle() {
      const body = document.getElementsByTagName('body')[0];
      const html = document.getElementsByTagName('html')[0];
      const width = html.clientWidth;
      const height = html.clientHeight;
      this.windowTurn=html.clientWidth<html.clientHeight?true:false;
      const max = width > height ? width : height;
      const min = width > height ? height : width;
      body.style.width = max + "px";
      body.style.height = min + "px";
      this.htmlHeight=min;
      this.htmlWidth=max;
      this.waterfallImgWidth=this.htmlWidth/4.89;
      this.waterfallImgRight=this.htmlWidth/66;
      this.waterfallImgBottom=this.htmlWidth/66;
      this.mainStyle="height:"+min + "px;"+"width:"+max + "px";
      // for(let n=0;n<this.waterfallList.length;n++)
      // {
      //   var imgData =this.waterfallList[n];
      // }
    },

    onWindowSizeChanged() {
      window.addEventListener("resize", this.forceLandscapeScreenHandle);
    },
    getStyleByBodyWd(  w,h){
      if(w==0)
        return  "height:"+ this.htmlHeight/h + "px;";
      else if(h==0)
        return "width:"+this.htmlWidth/w+ "px;";
      else
        return  "height:"+ this.htmlHeight/h + "px;"+"width:"+this.htmlWidth/w+ "px;";
    },
    homeRightButtonClick(){
      if(this.locator<4)
        this.locator=this.locator+1;
      this.$store.commit('set_locator_info',this.locator);
      if(this.locator==1){
        this.isHomeTransition=true;
        setTimeout(() => {
          this.isHomeTransition=false;
        }, 1000);
      }
    },
    homeLeftButtonClick(){
      if(this.locator>0){
        if(this.locator==2){
          this.locator=0
        }
        else{
          this.locator=this.locator-1;
        }
      }
      this.$store.commit('set_locator_info',this.locator);
    },
    caseCatalogChange(index,title){
      var url=  '/services/'+title;
      this.$router.push(url);
      this.locator=4;
      this.catalogSelect=index;
      this.locator=4;
      this.catalogSelect=index;
      this.$store.commit('set_locator_info',this.locator);
      this.$store.commit('set_case_info',this.catalogSelect);
      this.case_index=0;
      this.videoCaseIndex=0;

      this.isCaseTransition=true;
      setTimeout(() => {
        this.isCaseTransition=false;
      }, 400);
      this.waterfallList=[];
      this.imgPreloading();
      this.refareshBtn();
      this.waterfall_key++;
      // location.reload();
    },
    rebackCatalogPage(){
      this.locator=3;
    },

    refareshBtn(){

      this.isLoading=true;
      setTimeout(() =>{
        this.$forceUpdate();
        this.isLoading=false;
        this.case_detail=-1;
      }, 1600);
    },
    //计算每个图片的宽度或者是列数
    calculationWidth() {
      let domWidth=this.htmlWidth/1.4;
      // let domWidth = document.getElementById("v-waterfall").offsetWidth;
      if (!this.waterfallImgWidth && this.waterfallImgCol) {
        this.waterfallImgWidth = (domWidth - this.waterfallImgRight * this.waterfallImgCol) / this.waterfallImgCol;
      } else if (this.waterfallImgWidth && !this.waterfallImgCol) {
        this.waterfallImgCol = Math.floor(domWidth / (this.waterfallImgWidth + this.waterfallImgRight))
      }
      //初始化偏移高度数组
      this.waterfallDeviationHeight = new Array(this.waterfallImgCol);
      for (let i = 0; i < this.waterfallDeviationHeight.length; i++) {
        this.waterfallDeviationHeight[i] = 0;
      }
      this.imgPreloading();
      this.waterfall_key++;
      // this.imgCoverPreloading();
    },
    //图片预加载
    imgPreloading() {
      for (let i = 0; i < this.caseDetails[this.catalogSelect].length; i++) {

        let aImg = new Image();
        aImg.src =this.caseDetails[this.catalogSelect][i].cover;
        aImg.onload = aImg.onerror = () => {
          let imgData = {};
          imgData.orheight=aImg.height;
          imgData.orwidth=aImg.width;
          imgData.height = this.waterfallImgWidth / aImg.width * aImg.height;
          imgData.src =this.caseDetails[this.catalogSelect][i].cover;
          imgData.title = this.caseDetails[this.catalogSelect][i].title;
          imgData.id=i;
          imgData.top=0;
          this.waterfallList.push(imgData);


          // this.rankImg(imgData,i);
        }
      }
    },
    //waterfall stand
    imgModelPreloading() {
      for (let i = 0; i < this.waterfall_model.length; i++) {

        let aImg = new Image();
        aImg.src = this.waterfall_model[i].src;
        aImg.onload = aImg.onerror = () => {
          this.waterfall_model[i].height=aImg.height;
          this.waterfall_model[i].width= aImg.width;
        }
      }
    },

    getWaterfall_left( id)
    {
      return (id%3 ) * (this.waterfallImgRight + this.waterfallImgWidth);
    },
    //瀑布流布局
    rankImg(imgData,i) {
      let {
        waterfallImgWidth,
        waterfallImgRight,
        waterfallImgBottom,
        // waterfallDeviationHeight,
        // waterfallImgCol,
      } = this;
      // let minIndex = this.filterMin();
      if(Math.floor(imgData.id/3)>0)
      {
        imgData.top =this.waterfallList[i-3].top+ this.waterfallList[i-3].height+ waterfallImgBottom +this.htmlHeight/14 ;
      }else
      {
        imgData.top = Math.floor(imgData.id/3);
      }

      imgData.left = (imgData.id%3 ) * (waterfallImgRight + waterfallImgWidth);
      // waterfallDeviationHeight[minIndex] += imgData.height + waterfallImgBottom;// 不加文字的盒子高度

    },
    /**
     * 找到最短的列并返回下标
     * @returns {number} 下标
     */
    filterMin() {
      const min = Math.min.apply(null, this.waterfallDeviationHeight);

      return this.waterfallDeviationHeight.indexOf(min);
    },
    overHiddenText( s,n){

      if(s.length>n)
      {
        return   s.substr(0,n)+'...';
      }else
      {
        return s;
      }
    },
    imgsPreload( type, imgs){
      if(type==2)
      {
        for(let img of imgs)
        {

          let image =new Image();
          image.src  =img;

          image.onload=()=>{
          }
        }
      }else if(type==0)
      {
        let image =new Image();
        image.src  =imgs;
        image.onload=()=>{
        }
      }

    },

  },
}
</script>

<style scoped>

</style>
