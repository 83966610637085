<template>
  <!--  &lt;!&ndash; 案例介绍    &ndash;&gt;-->
  <div  style="  background: rgba(0, 0, 0, 1); "  :style="getStyleByBodyWd(1,1)"   >
    <div v-if="locator==3">
      <el-col  :style="getStyleByBodyWd(1,1)" :class="windowTurn?'index_3_whole_turn':'index_3_whole'">
        <el-row  style="align-items: flex-start;">
          <el-col :span="12"  style="text-align: left;">
            <div  :class="windowTurn?'left_top_zh_1_turn':'left_top_zh_1'">品牌设计</div>
            <div :class="windowTurn?'left_top_zh_2_turn':'left_top_zh_2'" >服务介绍</div>
            <div  :class="windowTurn?' left_top_en_turn left_case_blue_turn ':'left_top_en left_case_blue'" class="en_font_black" >Introduction to Brand Design Services</div>

          </el-col>
          <el-col :span="12" >
            <el-row class="right_top_page_2   "   style="display: flex;justify-content: flex-end">
              <div    >
                <div    :class="windowTurn?'right_top_title_zh_page_2_turn':'right_top_title_zh_page_2'  "   >品牌发展部</div>
                <div  :class="windowTurn?'right_top_title_en_page_2_turn':'right_top_title_en_page_2'  "  class="en_font_black" >Brand Development Department</div>
              </div>
              <div  ><img alt="text" src="../assets/title_right_top.svg"   :class="windowTurn?'top_right_logo_turn':'top_right_logo'" ></div>
            </el-row>
          </el-col>
        </el-row>
        <!--            <div  :class="windowTurn?'service_text_turn  ':'service_text  '" >易客品牌设计以品牌推广服务为核心，</div>-->
        <!--            <div  :class="windowTurn?'service_text_turn  service_text_margin_bt_turn':'service_text  service_text_margin_bt'" >涵盖品牌全案设计、包装设计、视觉设计、视频设计、交互设计、合作推广六大创新服务模块。</div>-->
        <el-row  >

          <div
              v-for="(o,index) in caseModels"
              :key="index"
              class=" button_pointer    flex_center service_box el-col-width el-col  "
              @click='caseCatalogChange(index,o.title_en)'
              :class="windowTurn?catalog_bg[index]:catalog_bg_web[index]"
              style="cursor:pointer;"
          >
            <div class="  case_catalog_block  "   :class="windowTurn?'case_block_turn':'case_block'"   >
              <div >
                <div  class="case_icon  "  >
                  <img :src="o.icon"  :class="windowTurn?'case_icon_size_turn':'case_icon_size'"    >
                </div>
                <div class="case_title_part">
                  <div :class="windowTurn?'caselist_title_turn':'caselist_title'" >{{o.title}}</div>
                  <img :class="windowTurn?'case_arrow_turn':'case_arrow'" src="../assets/arrow_case.svg">
                </div>
                <div  :class="windowTurn?'case_title_en_turn':'case_title_en'">{{o.title_en}}</div>
              </div>

              <div :class="windowTurn?'case_info_block_turn':'case_info_block'">
                <el-col v-for="(info,i) in o.infos"  :key="i" >
                  <div :class="windowTurn?'case_info_turn':'case_info'">{{info}}</div>
                </el-col>
              </div>
              <div></div>
              <!-- <div ><img src="./assets/more.svg"   :class="windowTurn?'case_more_turn':'case_more'" ></div> -->
            </div>
          </div>
        </el-row>
        <el-row  style="align-items:flex-end;">
          <el-col :span="12" :class="windowTurn?'margin_button_top_turn':'margin_button_top'">
            <div   class="home_button "   :style="getStyleByBodyWd(12,22)"  @click="getToPage('/team')" style=" display:flex;justify-content: flex-start; ">
              <img alt="text" src="../assets/btn_right.svg"  :style="getStyleByBodyWd(12,22)">
            </div>
          </el-col>
          <!--                <el-col :span="12"  :push="9"> <div   class="home_button "   :style="getStyleByBodyWd(12,22)"  @click="homeRightButtonClick()" ><img alt="text" src="./assets/left_arrow.png" :style="getStyleByBodyWd(12,22)" >  </div></el-col>-->
        </el-row>
      </el-col>
    </div>



  </div>


</template>

<script>


export default {
  name: "servicesPage",
  data() {
    return {
      locator:3,
      catalogSelect:0,
      case_detail:-1,
      htmlWidth:document.getElementsByTagName('html')[0].clientWidth,
      htmlHeight:document.getElementsByTagName('html')[0].clientHeight,
      windowTurn:false,
      catalog_bg_web:['catalog_bg_img_0','catalog_bg_img_1 box_shadow_web','catalog_bg_img_2 box_shadow_web','catalog_bg_img_3 box_shadow_web ','catalog_bg_img_4 box_shadow_web ','catalog_bg_img_5 box_shadow_web','catalog_bg_img_1 box_shadow_web'],
      catalog_bg:['catalog_bg_img_0','catalog_bg_img_1','catalog_bg_img_2','catalog_bg_img_3','catalog_bg_img_4','catalog_bg_img_5','catalog_bg_img_1'],
      asideBgImg:['aside-img0','aside-img1','aside-img2','aside-img3','aside-img4','aside-img5','aside-img1'],
      caseTitleGrad:['case_grad_color_0',"case_grad_color_1","case_grad_color_2","case_grad_color_3","case_grad_color_4","case_grad_color_5","case_grad_color_1"],
      caseTitleGradBottom:['case_grad_color_0_bottom',"case_grad_color_1_bottom","case_grad_color_2_bottom","case_grad_color_3_bottom","case_grad_color_4_bottom","case_grad_color_5_bottom","case_grad_color_1_bottom"],
      caseModels:this.$caseModels,
      caseShow:[],
      caseDetails: this.$caseDetails,
      videoCaseIndex:0,
      case_index:0,
      isLoading:false,
      isCaseTransition:false,
      initialIndex:0,
      //waterfall
      waterfallImgCol: 3,// 瀑布流的列数
      waterfallImgWidth: 0,// 每个盒子的宽度
      waterfallImgRight: 0,// 每个盒子的右padding
      waterfallImgBottom:0,// 每个盒子的下padding
      waterfallDeviationHeight: [],
      imgList: [],
      waterfallList: [],
      waterfall_key:0,
      waterfall_model:[
        {
          src:this.$mediaURL+'/case_2/cover/01.webp',
          width:0,
          height:0,
        },
        {
          src:this.$mediaURL+'/case_1/cover/02.webp',
          width:0,
          height:0,
        },

      ],
      url_list:[
          "Brand_Design",
          "Visual_Design",
          "Package_Design",
          "Video_Design",
          "User_Experience ",
          "Cooperative_Promotion",
      ],
    }
  },
  mounted() {


    var timeout = 3600000;
    if(localStorage.getItem("lastTime")!=null && Date.now() -localStorage.getItem("lastTime")<timeout )
    {
      console.log("没超");
      // if(localStorage.getItem("locator")==4)
      // {
      //   this.locator=localStorage.getItem("locator");
      //   this.catalogSelect=localStorage.getItem("catalogSelect");
      // }

    }else
    {
      localStorage.removeItem("locator");
      localStorage.removeItem("catalogSelect");
      this.$store.commit('set_lastTime');
    }


    this.init();
  },
  methods: {
    init() {
      this.forceLandscapeScreenHandle();
      // 这里监控
      this.onWindowSizeChanged();
    },
    getToPage(val){
      this.$router.push(val);
    },
    //图片预加载
    imgCoverPreloading() {
      for (let i = 0; i < this.caseDetails.length; i++) {

        let aImg = new Image();
        aImg.src =this.caseDetails[i][1].cover;
        aImg.onload = aImg.onerror = () => {
          let imgData = {};
          imgData.orheight=aImg.height;
          imgData.orwidth=aImg.width;
          imgData.height = this.waterfallImgWidth / aImg.width * aImg.height;
          imgData.src =this.caseDetails[i][1].cover;
          imgData.title = this.caseDetails[i][1].title;// 说明文字（也可以自己写数组，或者封装json数据，都可以，但是前提是你会相关操作，这里不赘述）

          imgData.id=i;

        }
      }



    },
    forceLandscapeScreenHandle() {
      const body = document.getElementsByTagName('body')[0];
      const html = document.getElementsByTagName('html')[0];
      const width = html.clientWidth;
      const height = html.clientHeight;
      this.windowTurn=html.clientWidth<html.clientHeight?true:false;
      const max = width > height ? width : height;
      const min = width > height ? height : width;
      body.style.width = max + "px";
      body.style.height = min + "px";
      this.htmlHeight=min;
      this.htmlWidth=max;
      this.waterfallImgWidth=this.htmlWidth/4.89;
      this.waterfallImgRight=this.htmlWidth/66;
      this.waterfallImgBottom=this.htmlWidth/66;
      this.mainStyle="height:"+min + "px;"+"width:"+max + "px";
      // for(let n=0;n<this.waterfallList.length;n++)
      // {
      //   var imgData =this.waterfallList[n];
      // }
    },
    onWindowSizeChanged() {
      window.addEventListener("resize", this.forceLandscapeScreenHandle);
    },
    getStyleByBodyWd(  w,h){
      if(w==0)
        return  "height:"+ this.htmlHeight/h + "px;";
      else if(h==0)
        return "width:"+this.htmlWidth/w+ "px;";
      else
        return  "height:"+ this.htmlHeight/h + "px;"+"width:"+this.htmlWidth/w+ "px;";
    },
    homeRightButtonClick(){
      if(this.locator<4)
        this.locator=this.locator+1;
      this.$store.commit('set_locator_info',this.locator);
      if(this.locator==1){
        this.isHomeTransition=true;
        setTimeout(() => {
          this.isHomeTransition=false;
        }, 1000);
      }
    },
    homeLeftButtonClick(){
      if(this.locator>0){
        if(this.locator==2){
          this.locator=0
        }
        else{
          this.locator=this.locator-1;
        }
      }
      this.$store.commit('set_locator_info',this.locator);
    },
    caseCatalogChange(index,  titles){
      var   s = titles.replace(/\s/g,"_");
      var url= encodeURI('/services/'+ s  )  ;
      this.$router.push(url);
      this.locator=4;
      this.catalogSelect=index;
      this.locator=4;
      this.catalogSelect=index;
      this.$store.commit('set_locator_info',this.locator);
      this.$store.commit('set_case_info',this.catalogSelect);
      this.case_index=0;
      this.videoCaseIndex=0;
      this.isCaseTransition=true;
      setTimeout(() => {
        this.isCaseTransition=false;
      }, 400);
      this.waterfallList=[];
      this.imgPreloading();
      this.refareshBtn();
      this.waterfall_key++;
      // location.reload();
    },
    rebackCatalogPage(){
      this.locator=3;
    },

    refareshBtn(){

      this.isLoading=true;
      setTimeout(() =>{
        this.$forceUpdate();
        this.isLoading=false;
        this.case_detail=-1;
      }, 1600);
    },
    //计算每个图片的宽度或者是列数
    calculationWidth() {
      let domWidth=this.htmlWidth/1.4;
      // let domWidth = document.getElementById("v-waterfall").offsetWidth;
      if (!this.waterfallImgWidth && this.waterfallImgCol) {
        this.waterfallImgWidth = (domWidth - this.waterfallImgRight * this.waterfallImgCol) / this.waterfallImgCol;
      } else if (this.waterfallImgWidth && !this.waterfallImgCol) {
        this.waterfallImgCol = Math.floor(domWidth / (this.waterfallImgWidth + this.waterfallImgRight))
      }
      //初始化偏移高度数组
      this.waterfallDeviationHeight = new Array(this.waterfallImgCol);
      for (let i = 0; i < this.waterfallDeviationHeight.length; i++) {
        this.waterfallDeviationHeight[i] = 0;
      }
      this.imgPreloading();
      this.waterfall_key++;
      // this.imgCoverPreloading();
    },
    //图片预加载
    imgPreloading() {
      for (let i = 0; i < this.caseDetails[this.catalogSelect].length; i++) {

        let aImg = new Image();
        aImg.src =this.caseDetails[this.catalogSelect][i].cover;
        aImg.onload = aImg.onerror = () => {
          let imgData = {};
          imgData.orheight=aImg.height;
          imgData.orwidth=aImg.width;
          imgData.height = this.waterfallImgWidth / aImg.width * aImg.height;
          imgData.src =this.caseDetails[this.catalogSelect][i].cover;
          imgData.title = this.caseDetails[this.catalogSelect][i].title;
          imgData.id=i;
          imgData.top=0;
          this.waterfallList.push(imgData);


          // this.rankImg(imgData,i);
        }
      }
    },
    //waterfall stand
    imgModelPreloading() {
      for (let i = 0; i < this.waterfall_model.length; i++) {

        let aImg = new Image();
        aImg.src = this.waterfall_model[i].src;
        aImg.onload = aImg.onerror = () => {
          this.waterfall_model[i].height=aImg.height;
          this.waterfall_model[i].width= aImg.width;
        }
      }
    },




  },

}
</script>

<style scoped>
.el-col-width{
  width:14.28%;
}
.debug_border{
  border: 1px solid #00ff6f;
}
</style>
