<template>
  <video   preload="auto"
           :autoplay="true"
           x5-video-player-fullscreen="true"
           webkit-playsinline   playsinline
           x5-video-player-type="h5"
           x5-video-orientation="landscape"
           width="100%"
           :poster="pre_src"
           class="video_item "  v-bind:src="s_url" controls style="object-fit: contain; border-radius: 10px;" ></video>

</template>

<script>
export default {
  name: "videoShow",
  props:   {
    s_url:String,
    pre_src:String,
  },
}
</script>

<style scoped>

</style>
