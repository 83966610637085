// main.ts

import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import store from '../../eser_guide/src/store/store'
import App from './App.vue'
import router from './router'



// import Vue3TouchEvents from "vue3-touch-events";
// 引入全局工具类
import untils from '../../eser_guide/src/utils/common';
import VueLazyLoad from 'vue-lazyload'
// Vue.use(VueLazyLoad,{
//     error:'./static/error.png',
//     loading:'./static/loading.png'
// })



// const compression = require('compression');
const mediaURL ='https://website.obs.icheer.cn/exhibition';
const app = createApp(App)
// app.use(compression());
app.use(ElementPlus)
app.use(store)
app.use(router)
app.use(VueLazyLoad,{
    // error:'./static/error.png',
    // loading:"https://download.icheer.cn/app/media/loading.webp",
})
// app.use(Vue3TouchEvents)
app.config.globalProperties.$mediaURL = mediaURL;
app.config.globalProperties.$caseModels=
    [
        {
            icon: require("@/assets/case_catalog/1.svg"),
            title:"品牌全案",
            title_en:"Brand full case design",
            infos:["品牌LOGO设计","品牌VI设计","IP形象设计","品牌形象升级"],
            logo_x:require('@/assets/case_1/icon/icon_x.svg'),
            logo_y: require('@/assets/case_1/icon/icon_y.svg'),
            waterfall:[],
        },
        {
            icon:require("@/assets/case_catalog/2.svg"),
            title:"视觉设计",
            title_en:"Visual design",
            infos:["电商首页设计","电商详情页设计", "网站/APP设计","产品手册设计","海报设计"],
            logo_x:require('@/assets/case_2/icon/icon_x.svg'),
            logo_y: require('@/assets/case_2/icon/icon_y.svg'),
            waterfall:[],
        },
        {
            icon:require("@/assets/case_catalog/3.svg"),
            title:"包装设计",
            title_en:"Package design",
            infos:["电子产品包装设计","食品包装设计","茶饮包装设计","酒水包装设计","文创包装设计"],
            logo_x:require('@/assets/case_3/icon/icon_x.svg'),
            logo_y: require('@/assets/case_3/icon/icon_y.svg'),
            waterfall:[],
        },
        {
            icon:require("@/assets/case_catalog/4.svg"),
            title:"视频设计",
            title_en:"Video design",
            infos:["脚本方案设计","企业宣传视频","三维动画视频","logo动效演绎","产品渲染图"],
            logo_x:require('@/assets/case_4/icon/video_logo_x.svg'),
            logo_y: require('@/assets/case_4/icon/video_logo_y.svg'),
            waterfall:[],
        },
        {
            icon:require("@/assets/case_catalog/5.svg"),
            title:"用户体验",
            title_en:"User experience ",
            infos:[ "原型设计","UI 设计", "UX设计"],
            logo_x:require('@/assets/case_5/icon/icon_x.svg'),
            logo_y: require('@/assets/case_5/icon/icon_y.svg'),
            waterfall:[],
        },
        {
            icon:require("@/assets/case_catalog/6.svg"),
            title:"展会设计",
            title_en:"Exhibition design",
            infos:["展厅空间设计","平面物料设计"],
            logo_x:require('@/assets/case_6/icon/icon_x.svg'),
            logo_y: require('@/assets/case_6/icon/icon_y.svg'),
            waterfall:[],

        },
        {
            icon:require("@/assets/case_catalog/7.svg"),
            title:"合作推广",
            title_en:"Cooperative promotion",
            infos:["公众号推广","视频号推广","衍生品设计"],
            logo_x:require('@/assets/case_2/icon/icon_x.svg'),
            logo_y: require('@/assets/case_2/icon/icon_y.svg'),
            waterfall:[],

        },
    ];
app.config.globalProperties.$caseDetails=
    [
        [
            {
                title:"小以LOGO设计",
                title_en: "xiaoyi_logo",
                type: 2,
                shape:0,
                cover:mediaURL+'/case_1/cover/00.jpg',
                info:"品牌LOGO设计",
                src:
                    [
                        mediaURL+'/case_1/main/00/内页1.jpg',
                        mediaURL+'/case_1/main/00/内页2.jpg',
                        mediaURL+'/case_1/main/00/内页3.jpg',
                        mediaURL+'/case_1/main/00/内页4.jpg',
                        mediaURL+'/case_1/main/00/内页5.jpg',
                        mediaURL+'/case_1/main/00/内页6.jpg',
                        mediaURL+'/case_1/main/00/内页7.jpg',
                    ]

            },
            {
                title:"电子烟LOGO设计",
                title_en: "e_cigarette_logo",
                type: 2,
                shape:1,
                cover:mediaURL+'/case_1/cover/02.webp',
                info:"LOGO与衍生设计",
                src:[
                    mediaURL+'/case_1/main/02/1-21.webp',
                    mediaURL+'/case_1/main/02/1-22.webp',
                    mediaURL+'/case_1/main/02/1-23.webp',
                    mediaURL+'/case_1/main/02/1-24.webp',
                ]

            },
            {
                title:"艾奇LOGO设计 ",
                title_en: "icheer_logo",
                type: 2,
                shape:0,
                cover:mediaURL+'/case_1/cover/icheerLogoCover.jpg',
                info:"VIS设计",
                src:
                    [
                        mediaURL+'/case_1/main/05/内页-1.jpg',
                        mediaURL+'/case_1/main/05/内页-2.jpg',
                        mediaURL+'/case_1/main/05/内页-3.jpg',
                        mediaURL+'/case_1/main/05/内页-4.jpg',
                        mediaURL+'/case_1/main/05/内页-5.jpg',
                        mediaURL+'/case_1/main/05/内页-6.jpg',
                    ]
            },

            {
                title:"旭美嘉LOGO设计",
                title_en: "AUDOTA_logo",
                type: 2,
                shape:1,
                cover:mediaURL+'/case_1/cover/03.webp',
                info:"LOGO与衍生设计",
                src:
                    [
                        mediaURL+'/case_1/main/03/1-16.webp',
                        mediaURL+'/case_1/main/03/1-17.webp',
                        mediaURL+'/case_1/main/03/1-18.webp',
                        mediaURL+'/case_1/main/03/1-19.webp',
                        mediaURL+'/case_1/main/03/1-20.webp',

                    ]
            },
            {
                title:"晶羚VI设计",
                title_en: "Jingling_vi",
                type: 2,
                shape:0,
                cover:mediaURL+'/case_1/cover/06.webp',
                info:"VIS设计",
                src:
                    [
                        mediaURL+'/case_1/main/06/1.webp',
                        mediaURL+'/case_1/main/06/2.webp',
                        mediaURL+'/case_1/main/06/3.webp',
                        mediaURL+'/case_1/main/06/4.webp',
                        mediaURL+'/case_1/main/06/5.webp',
                        mediaURL+'/case_1/main/06/6.webp',

                    ]

            },
            {
                title:"熊猫IP设计",
                title_en: "panda_ip",
                type: 2,
                shape:1,
                cover:mediaURL+'/case_1/cover/熊猫IP设计封面图.webp',
                info:"卡通IP与衍生设计",
                src:
                    [
                        mediaURL+'/case_1/main/04/37.webp',
                        mediaURL+'/case_1/main/04/38.webp',
                        mediaURL+'/case_1/main/04/39.webp',
                        mediaURL+'/case_1/main/04/40.webp',
                        mediaURL+'/case_1/main/04/41.webp',
                        mediaURL+'/case_1/main/04/42.webp',
                        mediaURL+'/case_1/main/04/43.webp',
                    ]

            },
            {
                title:"以色VI设计",
                title_en: "eser_vi",
                type: 2,
                shape:0,
                cover:mediaURL+'/case_1/cover/01.webp',
                info:"VIS设计",
                src:
                    [
                        mediaURL+'/case_1/main/01/01.webp',
                        mediaURL+'/case_1/main/01/02.webp',
                        mediaURL+'/case_1/main/01/03.webp',
                        mediaURL+'/case_1/main/01/04.webp',
                        mediaURL+'/case_1/main/01/05.webp',
                        mediaURL+'/case_1/main/01/06.webp',
                        mediaURL+'/case_1/main/01/07.webp',
                        mediaURL+'/case_1/main/01/08.webp',
                        mediaURL+'/case_1/main/01/09.webp',
                        mediaURL+'/case_1/main/01/10.webp',
                        mediaURL+'/case_1/main/01/11.webp',
                        mediaURL+'/case_1/main/01/12.webp',
                        mediaURL+'/case_1/main/01/13.webp',
                        mediaURL+'/case_1/main/01/14.webp',

                    ]

            },


        ],
        [
            {
                title:"产品手册",
                title_en: "Product_manual",
                type: 2,
                shape:0,
                cover:mediaURL+'/case_2/cover/01.webp',
                info:"企业/产品书刊设计装帧",
                src:[
                    mediaURL+'/case_2/main/01/1.webp',
                    mediaURL+'/case_2/main/01/2.webp',
                    mediaURL+'/case_2/main/01/3.webp',
                ],
            },
            {
                title:"香薰机-电商详情页",
                title_en:"E-commerce_Details_Page" ,
                type: 2,
                shape:1,
                cover:mediaURL+'/case_2/cover/02.webp',
                info:"电商产品详情",
                src:[
                    mediaURL+'/case_2/main/02/01.webp',
                    mediaURL+'/case_2/main/02/02.webp',
                ],

            },
            {
                title:"电商首页",
                title_en: "E-commerce_Details",
                type: 2,
                shape:0,
                cover:mediaURL+'/case_2/cover/03.webp',
                info:"电商产品详情",
                src:[
                    mediaURL+'/case_2/main/03/1.webp',
                    mediaURL+'/case_2/main/03/2.webp',
                ],
            },
            {
                title:"昆泰芯产品手册",
                title_en: "kunten_manual",
                type: 2,
                shape:1,
                cover:mediaURL+'/case_2/cover/产品手册封面图.webp',
                info:"企业/产品书刊设计装帧",
                src:[
                    mediaURL+'/case_2/main/09/内页1.webp',
                    mediaURL+'/case_2/main/09/内页2.webp',
                ],
            },

            {
                title:"松下详情页",
                title_en: "Panasonic_details",
                type: 2,
                shape:0,
                cover:mediaURL+'/case_2/cover/05.webp',
                info:"电商产品详情设计",
                src:[
                    mediaURL+'/case_2/main/05/05-01.webp',
                    mediaURL+'/case_2/main/05/05-02.webp',
                    mediaURL+'/case_2/main/05/05-03.webp',
                    mediaURL+'/case_2/main/05/05-04.webp',
                    mediaURL+'/case_2/main/05/05-05.webp',

                ],
            },
            {
                title:"海报设计",
                title_en: "Poster_Design ",
                type: 2,
                shape:1,
                cover:mediaURL+'/case_2/cover/06.webp',
                info:"商业海报设计",
                src:[

                    mediaURL+'/case_2/main/06/01.webp',
                    mediaURL+'/case_2/main/06/02.webp',
                    mediaURL+'/case_2/main/06/04.webp',
                    mediaURL+'/case_2/main/06/03.webp',


                ],
            },
            {
                title:"以色手册",
                title_en: "Eser_Manual_Design",
                type: 2,
                shape:0,
                cover:mediaURL+'/case_2/cover/07.webp',
                info:"企业/产品书刊设计装帧",
                src:[
                    mediaURL+'/case_2/main/11/01.webp',
                    mediaURL+'/case_2/main/11/02.webp',
                ],
            },
            {
                title:"APP设计",
                title_en: "APP_Design",
                type: 2,
                shape:1,
                cover:mediaURL+'/case_2/cover/08.webp',
                info:"内容规划与版面设计",
                src:[
                    mediaURL+'/case_2/main/04/1.webp',
                    mediaURL+'/case_2/main/04/2.webp',
                    mediaURL+'/case_2/main/04/3.webp',
                    mediaURL+'/case_2/main/04/4.webp',
                ],
            },
            {
                title:"艾奇官网" ,
                title_en: "Icheer_Website",
                type: 0,
                shape:1,
                cover:mediaURL+'/case_2/cover/04.webp',
                info:"内容规划与版面设计",
                src:  mediaURL+'/case_2/艾奇官网.webp',
            },

            {
                title:"独立站banner",
                title_en: "Website_banner",
                type: 2,
                shape:1,
                cover:mediaURL+'/case_2/cover/10.webp',
                info:"电商物料设计",
                src:[
                    mediaURL+'/case_2/main/07/01.webp',
                    mediaURL+'/case_2/main/07/02.webp',
                    mediaURL+'/case_2/main/07/03.webp',

                ],
            },
            {
                title:"以色企业官网",
                title_en: "Eser_Website",
                type:0,
                shape:0,
                cover:mediaURL+'/case_2/cover/11.webp',
                info:"内容规划与版面设计",
                src:  mediaURL+'/case_2/以色企业官网.webp',

            },
            {
                title:"亚马逊",
                title_en: "Amazon_Details",
                type: 2,
                shape:0,
                cover:mediaURL+'/case_2/cover/12.webp',
                info:"电商物料设计",
                src:[
                    mediaURL+'/case_2/main/08/01.webp',
                    mediaURL+'/case_2/main/08/02.webp',
                    mediaURL+'/case_2/main/08/03.webp',
                    mediaURL+'/case_2/main/08/04.webp',
                    mediaURL+'/case_2/main/08/05.webp',
                    mediaURL+'/case_2/main/08/06.webp',


                ],
            },



            // {
            //   title:"昆泰官网" ,
            //   type: 0,
            //   shape:0,
            //   cover:mediaURL+'/case_2/cover/12.webp',
            //   info:"内容规划与版面设计",
            //   src:  mediaURL+'/case_2/main/12/01.jpg',
            // },

        ],
        [
            {
                title:"武川藜麦包装设计",
                title_y: "武川藜麦包装设计",
                title_en: "wuChuan",
                type: 2,
                shape:0,
                cover:mediaURL+'/case_3/武川藜麦包装设计/cover.webp',
                info:"礼盒包装设计",
                src:
                    [
                        mediaURL+'/case_3/武川藜麦包装设计/1.webp',
                        mediaURL+'/case_3/武川藜麦包装设计/2.webp',
                        mediaURL+'/case_3/武川藜麦包装设计/3.webp',
                        mediaURL+'/case_3/武川藜麦包装设计/4.webp',
                    ]

            },
            {
                title:"鲜牛奶包装设计",
                title_y: "鲜牛奶包装设计",
                title_en: "milkPackage",
                type: 2,
                shape:0,
                cover:mediaURL+'/case_3/鲜牛奶包装设计/封面图.png',
                info:"茶饮包装设计",
                src:
                    [
                        mediaURL+'/case_3/鲜牛奶包装设计/内页-1.png',
                        mediaURL+'/case_3/鲜牛奶包装设计/内页-2.png',
                        mediaURL+'/case_3/鲜牛奶包装设计/内页-3.png',
                        mediaURL+'/case_3/鲜牛奶包装设计/内页-4.png',
                        mediaURL+'/case_3/鲜牛奶包装设计/内页-5.png',
                        mediaURL+'/case_3/鲜牛奶包装设计/内页-6.png',
                    ]

            },
            {
                title:"比利时精酿啤酒包装设计",
                title_y: "精酿啤酒包装设计",
                title_en: "billPackage",
                type: 2,
                shape:0,
                cover:mediaURL+'/case_3/比利时精酿啤酒/cover.webp',
                info:"酒水包装设计",
                src:
                    [
                        mediaURL+'/case_3/比利时精酿啤酒/1.webp',
                        mediaURL+'/case_3/比利时精酿啤酒/2.webp',
                        mediaURL+'/case_3/比利时精酿啤酒/3.webp',
                        mediaURL+'/case_3/比利时精酿啤酒/4.webp',
                        mediaURL+'/case_3/比利时精酿啤酒/5.webp',
                        mediaURL+'/case_3/比利时精酿啤酒/6.webp',
                        mediaURL+'/case_3/比利时精酿啤酒/7.webp',
                    ]

            },
            {
                title:"无线传输器包装设计",
                title_y: "传输器包装设计",
                title_en: "wireLessPackage",
                type: 2,
                shape:1,
                cover:mediaURL+'/case_3/无线传输器/cover1.webp',
                info:"电子产品包装设计",
                src:
                    [
                        mediaURL+'/case_3/无线传输器/main.webp',
                    ]

            },

            {
                title:"电吹管包装设计",
                title_y: "电吹管包装设计",
                title_en: "eMusicPackage",
                type: 2,
                shape:1,
                cover:mediaURL+'/case_3/cover/04.webp',
                info:"电子产品包装设计",
                src:
                    [
                        mediaURL+'/case_3/main/04/1.webp',
                    ]

            },
            {
                title:"以色产品包装设计",
                title_y: "以色产品包装设计",
                title_en: "eserPackage",
                type: 2,
                shape:1,
                cover:mediaURL+'/case_3/cover/01.webp',
                info:"电子产品包装设计",
                src:
                    [
                        mediaURL+'/case_3/main/01/01.webp',
                    ]

            },
            {
                title:"体育彩票设计",
                title_y: "体育彩票设计",
                title_en: "lotteryDesign",
                type: 2,
                shape:0,
                cover:mediaURL+'/case_3/体育彩票设计/cover.webp',
                info:"文创包装设计",
                src:
                    [
                        mediaURL+'/case_3/体育彩票设计/1.webp',
                        mediaURL+'/case_3/体育彩票设计/2.webp',
                        mediaURL+'/case_3/体育彩票设计/3.webp',
                        mediaURL+'/case_3/体育彩票设计/4.webp',
                        mediaURL+'/case_3/体育彩票设计/5.webp',
                        mediaURL+'/case_3/体育彩票设计/6.webp',
                        mediaURL+'/case_3/体育彩票设计/7.webp',
                        mediaURL+'/case_3/体育彩票设计/8.webp',
                    ]

            },
            {
                title:"新西兰红酒包装设计",
                title_y: "红酒包装设计",
                title_en: "winePackage",
                type: 2,
                shape:0,
                cover:mediaURL+'/case_3/新西兰红酒包装设计/cover.webp',
                info:"酒水包装设计",
                src:
                    [
                        mediaURL+'/case_3/新西兰红酒包装设计/1.webp',
                        mediaURL+'/case_3/新西兰红酒包装设计/2.webp',


                    ]

            },
            {
                title:"运动手表包装设计",
                title_y: "运动手表包装设计",
                title_en: "watchPackage",
                type: 2,
                shape:0,
                cover:mediaURL+'/case_3/cover/05.webp',
                info:"电子产品包装设计",
                src:
                    [
                        mediaURL+'/case_3/main/05/1.webp',
                        mediaURL+'/case_3/main/05/2.webp',
                        mediaURL+'/case_3/main/05/3.webp',
                        mediaURL+'/case_3/main/05/4.webp',
                    ]

            },
            {
                title:"易客创新手提袋",
                title_y: "易客创新手提袋",
                title_en: "handbagDesign",
                type: 2,
                shape:1,
                cover:mediaURL+'/case_3/cover/03.webp',
                info:"文创包装设计",
                src:
                    [
                        mediaURL+'/case_3/main/03/03-01.webp',
                    ]

            },

            {
                title:"茶叶包装设计",
                title_y: "茶叶包装设计",
                title_en: "teaPackage",
                type: 2,
                shape:0,
                cover:mediaURL+'/case_3/cover/06.webp',
                info:"茶饮包装设计",
                src:
                    [
                        mediaURL+'/case_3/main/06/01.webp',

                    ]

            },


        ],
        [
            {
                title:"产品系列推广视频（一）",
                title_y: "产品推广视频",
                title_en:"Promotion_video_01",
                type: 1,
                shape:0,
                cover: mediaURL+"/case_4/cover/new/01.webp",
                info:"脚本设计与产品渲染",
                preview: mediaURL+"/case_4/cover/new/01.webp",
                src: mediaURL+"/case_4/video/昆泰磁旋钮更清晰.mp4",
            },
            {
                title:"产品系列推广视频（二）",
                title_y: "产品推广视频",
                title_en:"Promotion_video_02",
                type: 1,
                shape:0,
                cover: mediaURL+"/case_4/cover/new/02.webp",
                info:"脚本设计与产品渲染",
                preview: mediaURL+"/case_4/cover/new/02.webp",
                src: mediaURL+"/case_4/video/推广视频二.mp4",
            },
            {
                title:"产品海外推广视频",
                title_en:"Promotion_video_03",
                type: 1,
                shape:0,
                cover: mediaURL+"/case_4/cover/new/03.webp",
                info:"脚本设计与产品渲染",
                preview:mediaURL+"/case_4/cover/new/03.webp",
                src: mediaURL+"/case_4/video/海外宣传视频.mp4",
            },
            {
                title:"红点奖音箱",
                title_en:"Qian'an_speaker",
                type: 1,
                shape:1,
                cover: mediaURL+"/case_4/cover/new/04.webp",
                info:"脚本设计与产品渲染",
                preview:mediaURL+"/case_4/cover/new/04.webp",
                src: mediaURL+"/case_4/video/千岸音箱案例视频.mp4",
            },
            {
                title:"华为智选滑板车",
                title_en:"scooter",
                type: 1,
                shape:0,
                cover:  mediaURL+"/case_4/cover/new/05.webp",
                info:"脚本设计与产品渲染",
                preview:mediaURL+"/case_4/cover/new/05.webp",
                src:mediaURL+"/case_4/video/乐骑电动滑板车.mp4",
            },
            {
                title:"公司企宣视频",
                title_en:"Promotion_video_07",
                type: 1,
                shape:0,
                cover: mediaURL+"/case_4/cover/new/07.webp",
                info:"企业宣传视频",
                preview:mediaURL+"/case_4/cover/new/07.webp",
                src: "https://intranet.obs.cn-fz1.ctyun.cn:443/odsstore%2F6ffa3bff-31e6-49dc-9561-04cc773d05fa.mp4",
            },
            {
                title:"伴宠机器人",
                title_en:"Companion_robot",
                type: 1,
                shape:1,
                cover: mediaURL+"/case_4/cover/new/06.webp",
                preview:mediaURL+"/case_4/cover/new/06.webp",
                info:"脚本设计与产品渲染",
                src:mediaURL+"/case_4/video/伴宠机器人.mp4",
            },

            {
                title:"储能电源",
                title_en:"Outdoor_power",
                type: 1,
                shape:0,
                cover: mediaURL+"/case_4/cover/new/08.webp",
                info:"脚本设计与产品渲染",
                preview:mediaURL+"/case_4/cover/new/08.webp",
                src: mediaURL+"/case_4/video/户外电源.mp4",
            },
            {
                title:"补水仪",
                title_en:"Blue_light_beauty_instrument",
                type: 1,
                shape:0,
                cover: mediaURL+"/case_4/cover/new/09.webp",
                info:"脚本设计与产品渲染",
                preview:mediaURL+"/case_4/cover/new/09.webp",
                src:mediaURL+"/case_4/video/注氧仪-30s.mp4",
            },
            {
                title:"CP800投影仪",
                title_en:"CP800_projector",
                type: 1,
                shape:0,
                cover: mediaURL+"/case_4/cover/new/10.webp",
                info:"脚本设计与产品渲染",
                preview:mediaURL+"/case_4/cover/new/10.webp",
                src:mediaURL+"/case_4/video/投影仪.mp4",
            },
            {
                title:"香氛机",
                title_en:"Fragrance_machine",
                type: 1,
                shape:1,
                cover: mediaURL+"/case_4/cover/new/11.webp",
                info:"脚本设计与产品渲染",
                preview:mediaURL+"/case_4/cover/new/11.webp",
                src:mediaURL+"/case_4/video/lei无电香氛机.mp4",
            },
            {
                title:"ODS按摩仪",
                title_en:"ODS_massager",
                type: 1,
                shape:1,
                cover: mediaURL+"/case_4/cover/new/12.webp",
                info:"脚本设计与产品渲染",
                preview:mediaURL+"/case_4/cover/new/12.webp",
                src:mediaURL+"/case_4/video/ODS.mp4",
            },
            {
                title:"酷乐电吹管",
                title_en:"Kule_electronic_flute",
                type: 1,
                shape:0,
                cover: mediaURL+"/case_4/cover/new/13.webp",
                info:"脚本设计与产品渲染",
                preview:mediaURL+"/case_4/cover/new/13.webp",
                src:mediaURL+"/case_4/video/酷乐电吹管视频47S.mp4",
            },
            {
                title:"二合一无线充",
                title_en:"wireless_charging",
                type: 1,
                shape:1,
                cover: mediaURL+"/case_4/cover/new/14.webp",
                info:"脚本设计与产品渲染",
                preview:mediaURL+"/case_4/cover/new/14.webp",
                src:mediaURL+"/case_4/video/无线充.mp4",
            },




        ],
        [
            {
              title:"暖宫按摩仪app",
              type: 0,
              shape:0,
              cover:mediaURL+'/case_5/cover/1暖宫按摩仪APP封面图.webp',
              src: mediaURL+'/case_5/main/用户体验_暖宫按摩仪.webp',
            },
            {
                title:"蓝牙电动牙刷APP",
                title_en:"Toothbrush_APP" ,
                type: 0,
                shape:0,
                cover:mediaURL+'/case_5/cover/2蓝牙电动牙刷app封面图.webp',
                src: mediaURL+'/case_5/main/02.webp',
            },
            {
                title:"表盘＆图标",
                title_en: "clock_dial_Design",
                type: 0,
                shape:0,
                cover:mediaURL+'/case_5/cover/3表盘与图标设计封面图.webp',
                src: mediaURL+'/case_5/main/03.webp',
            },
            {
                title:"智能手表APP",
                title_en: "Ewatch_APP",
                type: 0,
                shape:0,
                cover:mediaURL+'/case_5/cover/4易客智能手表app封面图.webp',
                src: mediaURL+'/case_5/main/04.webp',
            },
            // {
            //   title:"红外加热器",
            //   type: 0,
            //   shape:0,
            //   cover:mediaURL+'/case_5/cover/05.webp',
            //   src: mediaURL+'/case_5/main/05.webp',
            // },
            {
                title:"智能黑头仪APP",
                title_en: "electronic_APP",
                type: 0,
                shape:0,
                cover:mediaURL+'/case_5/cover/5智能黑头仪app封面图.webp',
                src: mediaURL+'/case_5/main/06.webp',
            },
            {
                title:"由美健康检测APP",
                title_en: "health_APP",
                type: 0,
                shape:0,
                cover:mediaURL+'/case_5/cover/6由美健康检测app封面图.webp',
                src: mediaURL+'/case_5/main/07.webp',
            },
            {
                title:"贝尔储能电源",
                title_en: "Bell_Energy ",
                type: 0,
                shape:0,
                cover:mediaURL+'/case_5/cover/7高斯贝尔电源显示屏封面图.webp',
                src: mediaURL+'/case_5/main/08.webp',
            },
            {
                title:"汽车资源管理",
                title_en: "carSystem_design",
                type: 0,
                shape:0,
                cover:mediaURL+'/case_5/cover/8汽车管理系统封面图.webp',
                src: mediaURL+'/case_5/main/0900.webp',
            },
            {
                title:"储能电源显示屏",
                title_en: "Power_Display",
                type: 0,
                shape:0,
                cover:mediaURL+'/case_5/cover/9储能电源显示屏设计封面图.webp',
                src: mediaURL+'/case_5/main/10.webp',
            },
            {
                title:"天籁海盗听书",
                title_en: "BookListen_APP",
                type: 0,
                shape:0,
                cover:mediaURL+'/case_5/cover/10天籁海盗听书封面图.webp',
                src: mediaURL+'/case_5/main/11.webp',
            },
            {
                title:"智能手表" ,
                title_en: "e-Watch",
                type: 0,
                shape:0,
                cover:mediaURL+'/case_5/cover/11智能手表封面图.webp',
                src: mediaURL+'/case_5/main/12.webp',
            },
            {
                title:"车载管理界面" ,
                title_en: "car_system_display",
                type: 0,
                shape:0,
                cover:mediaURL+'/case_5/cover/12车载管理界面封面图.webp',
                src: mediaURL+'/case_5/main/1300.webp',
            },
        ],
        [
            {
                title:"展厅空间设计（Sensor SZ）",
                title_en: "Exhibition_space_design",
                title_y:"展厅空间设计丨深圳",
                type: 2,
                cover:mediaURL+'/展会设计/展厅空间设计_SZ/cover.webp',
                info:"展厅空间设计",
                src:  [
                    mediaURL+'/展会设计/展厅空间设计_SZ/FFOutput/1.webp',
                    mediaURL+'/展会设计/展厅空间设计_SZ/FFOutput/2.webp',
                    mediaURL+'/展会设计/展厅空间设计_SZ/FFOutput/3.webp',
                    mediaURL+'/展会设计/展厅空间设计_SZ/FFOutput/4.webp',
                    mediaURL+'/展会设计/展厅空间设计_SZ/FFOutput/5.webp',
                ]

            },
            {
                title:"平面物料设计（Sensor SZ）",
                title_en: "Advert_material_design",
                title_y:"平面物料设计丨深圳",
                type: 2,
                cover:mediaURL+'/展会设计/平面物料设计_SZ/cover.webp',
                info:"平面物料设计",
                src: [
                    mediaURL+'/展会设计/平面物料设计_SZ/FFOutput/1.webp',
                    mediaURL+'/展会设计/平面物料设计_SZ/FFOutput/2.webp',
                    mediaURL+'/展会设计/平面物料设计_SZ/FFOutput/3.webp',
                    mediaURL+'/展会设计/平面物料设计_SZ/FFOutput/4.webp',
                    mediaURL+'/展会设计/平面物料设计_SZ/FFOutput/5.webp',
                    mediaURL+'/展会设计/平面物料设计_SZ/FFOutput/6.webp',
                    mediaURL+'/展会设计/平面物料设计_SZ/FFOutput/7.webp',
                    mediaURL+'/展会设计/平面物料设计_SZ/FFOutput/8.webp',
                    mediaURL+'/展会设计/平面物料设计_SZ/FFOutput/9.webp',


                ]
            },
            {
                title:"展厅效果实拍（Sensor SZ）",
                title_en: "Exhibition_design",
                title_y:"展厅效果实拍丨深圳",
                type: 2,
                cover:mediaURL+'/展会设计/展厅实拍效果_SZ/cover.webp',
                info:"展厅空间设计",
                src:[
                    mediaURL+'/展会设计/展厅实拍效果_SZ/FFOutput/1.webp',
                    mediaURL+'/展会设计/展厅实拍效果_SZ/FFOutput/2.webp',
                    mediaURL+'/展会设计/展厅实拍效果_SZ/FFOutput/3.webp',
                    mediaURL+'/展会设计/展厅实拍效果_SZ/FFOutput/4.webp',
                    mediaURL+'/展会设计/展厅实拍效果_SZ/FFOutput/5.webp',
                    mediaURL+'/展会设计/展厅实拍效果_SZ/FFOutput/6.webp',
                    mediaURL+'/展会设计/展厅实拍效果_SZ/FFOutput/7.webp',
                    mediaURL+'/展会设计/展厅实拍效果_SZ/FFOutput/8.webp',
                    mediaURL+'/展会设计/展厅实拍效果_SZ/FFOutput/9.webp',
                    mediaURL+'/展会设计/展厅实拍效果_SZ/FFOutput/10.webp',

                ]

            },
            {
                title:"线上推广物料（Sensor SZ）",
                title_en: "Online_materials_design",
                title_y:"线上推广物料丨深圳",
                type: 2,
                cover:mediaURL+'/展会设计/线上推广物料_SZ/cover.webp',
                info:"平面物料设计",
                src: [
                    mediaURL+'/展会设计/线上推广物料_SZ/FFOutput/1.webp',
                    mediaURL+'/展会设计/线上推广物料_SZ/FFOutput/2.webp',
                ]
            },

            {
                title:"平面物料设计（Sensor+test）",
                title_en: "Graphic_material_design",
                title_y:"平面物料设计丨纽伦堡",
                type: 2,
                cover:mediaURL+'/展会设计/平面物料设计_ST/cover.webp',
                info:"平面物料设计",
                src:[
                    mediaURL+'/展会设计/平面物料设计_ST/FFOutput/1.webp',
                    mediaURL+'/展会设计/平面物料设计_ST/FFOutput/2.webp',
                    mediaURL+'/展会设计/平面物料设计_ST/FFOutput/3.webp',
                ]

            },
            {
                title:"展厅效果实拍（Sensor+test）",
                title_en: "Exhibition_hall_design",
                title_y:"展厅效果实拍丨纽伦堡",
                type: 2,
                cover:mediaURL+'/展会设计/展厅效果实拍_ST/cover.webp',
                info:"展厅空间设计",
                src: [
                    mediaURL+'/展会设计/展厅效果实拍_ST/FFOutput/1.webp',
                    mediaURL+'/展会设计/展厅效果实拍_ST/FFOutput/2.webp',
                    mediaURL+'/展会设计/展厅效果实拍_ST/FFOutput/3.webp',
                    mediaURL+'/展会设计/展厅效果实拍_ST/FFOutput/4.webp',
                ]
            },
        ],
        [
            {
                title:"蓝牙BLE X 暖宫按摩仪 | 你看city不city?",
                title_en: "bleRuanGongBao",
                title_y:"暖宫按摩仪",
                text:"实力，next level!",
                type: 3,
                cover:mediaURL+'/case_6/暖宫按摩仪/封面图.jpg',
                info:"文章编辑与视觉排版",
                src: mediaURL+'/case_6/暖宫按摩仪/内页.jpg',

            },
            {
                title:"以色 X 医疗健康 | 是设计亦是关怀",
                title_en: "EserHealth",
                title_y:"以色·医疗健康",
                text:"设计赋能健康生活，让呵护近在咫尺",
                type: 3,
                cover:mediaURL+'/case_6/以色医疗健康/封面图.jpg',
                info:"文章编辑与视觉排版",
                src: mediaURL+'/case_6/以色医疗健康/内页.jpg',

            },
            {
                title:"以色 X 弗犹彻 | 红点设计大奖产品诞生记",
                title_en: "Red_Dot_Design_1",
                title_y:"弗犹彻·眼部按摩仪",
                text:"论如何拿下最迎合国际设计大奖之最硬核类别奖项",
                type: 3,
                cover:mediaURL+'/case_6/cover/01.webp',
                info:"文章与视觉排版",
                src: mediaURL+'/case_6/main/01.webp',

            },
            {
                title:"囿于厨房与爱的魔法 | 定义新美学，挑战小空间",
                title_en: "clean_product",
                title_y:"飞立魔方",
                text:"打破常规新魔方，由内而外双创新",
                type: 3,
                cover:mediaURL+'/case_6/cover/02.webp',
                info:"文章编辑与视觉排版",
                src: mediaURL+'/case_6/main/02.webp',
            },
            {
                title:"电风扇 | 产品设计背后的从零到N",
                title_en: "Electric_Fan",
                title_y:"以色访谈",
                text:"由用户需求本质出发，每一次设计都是以人为本",
                type: 3,
                cover:mediaURL+'/case_6/cover/04.webp',
                info:"文章编辑与视觉排版",
                src: mediaURL+'/case_6/main/04.webp',
            },

            {
                title:"暖手更暖心 | 企鹅暖手宝设计",
                title_en: "Hand_Warmer",
                title_y:"企鹅暖手宝设计",
                text:"从古至今，人类从未停止在“暖手”神器上的探索",
                type: 3,
                cover:mediaURL+'/case_6/cover/10.webp',
                info:"文章编辑与视觉排版",
                src: mediaURL+'/case_6/main/10.webp',
            },
            {
                title:"易起来，耀出彩",
                title_en: "Onboard_Training",
                title_y:"易起来，耀出彩",
                text:"抓住校招尾巴，赶紧上车",
                type: 3,
                cover:mediaURL+'/case_6/cover/05.webp',
                info:"文章编辑与视觉排版",
                src: mediaURL+'/case_6/main/05.webp',
            },
            {
                title:"惊！拿设计界奥斯卡小金人也有套路？",
                title_en: "Red_Dot_Design_Talk",
                title_y:"漫谈红点",
                text:"看红点奖得主+高校大咖现场揭秘，知道这些，你也可以拿设计大奖~",
                type: 1,
                cover:mediaURL+'/case_6/cover/09.webp',
                info:"文章编辑与视觉排版",
                src: mediaURL+'/case_6/main/redTalk.mp4',
            },
            {
                title:"ODS × 蓝牙低频按摩仪 | 成熟全案服务上线",
                title_en: "ODS_Massager",
                title_y:"ODS蓝牙按摩仪",
                text:"找对伙伴，选品再也不用挖呀挖",
                type: 3,
                cover:mediaURL+'/case_6/cover/03.webp',
                info:"文章编辑与视觉排版",
                src: mediaURL+'/case_6/main/03.webp',
            },


            {
                title:"闷头搞大事，中控车间泰酷辣",
                title_en: "electronic_Factory",
                title_y:"中控车间",
                text:"一起来看看厂长的江山叭",
                type: 3,
                cover:mediaURL+'/case_6/cover/06.webp',
                info:"文章编辑与视觉排版",
                src: mediaURL+'/case_6/main/06.webp',
            },
            {
                title:"萌新大牛“奇”遇计",
                title_en: "new_icheer",
                title_y:"艾奇·奇遇记",
                text:"抓住校招尾巴，赶紧上车",
                type: 3,
                cover:mediaURL+'/case_6/cover/07.webp',
                info:"文章编辑与视觉排版",
                src: mediaURL+'/case_6/main/07.webp',
            },
            {
                title:"美·力张扬 | 美·好世界",
                title_en: "Women_Day",
                title_y:"女神节",
                text:" 女神节快乐！",
                type: 2,
                cover:mediaURL+'/case_6/cover/08.webp',
                info:"文章编辑与视觉排版",
                // src:mediaURL+'/case_6/main/08.mp4',
                src: [mediaURL+'/case_6/main/38women.gif',mediaURL+'/case_6/main/0802.webp'],
            },

        ],
    ];
app.config.globalProperties.$untils = untils
app.mount('#app')



