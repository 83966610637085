<template>
  <!--  &lt;!&ndash;  团队成员  &ndash;&gt;-->
  <div  style="  background: rgba(0, 0, 0, 1); "   >
    <div   class="container_bgImg_team"   :style="getStyleByBodyWd(1,1)"   >
      <el-row  class="home_main" >
        <!-- 首页locator_0 -->
        <el-col    :style="getStyleByBodyWd(1,1)" :class="windowTurn?'index_2_whole_padding_turn':'index_2_whole_padding'" >
          <el-row    class="row_flex_between  "  style="align-items:flex-start;">
            <el-col :span="12"  style="text-align: left;">
              <div  :class="windowTurn?'left_top_zh_1_turn':'left_top_zh_1'" >易客创新</div>
              <div :class="windowTurn?'left_top_zh_2_turn':'left_top_zh_2'"  >品牌团队介绍</div>

              <div  :class="windowTurn?' left_top_en_turn left_blue_margin_bom_turn ':'left_top_en left_blue_margin_bom '"  class="en_font_black" >Brand Team Introduction</div>
              <div :class="windowTurn?'team_introduction_part_turn':'team_introduction_part'" >
                <div :class="windowTurn?'team_introduction_part_1_turn':'team_introduction_part_1'">易客品牌团队成立于2021年4月，由十二位创新设计人才组成，具有视觉传达、艺术设计、数字媒体艺术、新闻传播等专业学术背景，并拥有知名企业品牌设计项目服务经验，商业实践经验丰富。</div>
                <div :class="windowTurn?'company_info_en_mobile':'company_info_en_web'">The EIKER brand team was established in April 2021, comprising twelve innovative design talents with professional academic backgrounds in visual communication, art design, digital media arts, and journalism, as well as extensive experience in serving renowned corporate
                  brand design projects and rich commercial practice.</div>
              </div>
            </el-col>
            <el-col :span="12"     style="align-items: center">
              <el-row class="right_top_page_2     "    >
                <div    >
                  <div    :class="windowTurn?'right_top_title_zh_page_2_turn':'right_top_title_zh_page_2'  "   >品牌发展部</div>
                  <div  :class="windowTurn?'right_top_title_en_page_2_turn':'right_top_title_en_page_2'  "  class="en_font_black"  >Brand Development Department</div>
                </div>
                <div ><img alt="text" src="../assets/title_right_top.svg"  :class="windowTurn?'top_right_logo_turn':'top_right_logo' " ></div>
              </el-row>
            </el-col>
          </el-row>

          <el-row  style="display:flex;justify-content:space-between;">
            <el-col :span="3" >
              <div   class="home_button "   :style="getStyleByBodyWd(12,22)"  @click=" getToPage('/index')" style=" display:flex;justify-content: flex-start;">
                <img alt="text" src="../assets/btn_right.svg" :style="getStyleByBodyWd(12,22)">
              </div>
            </el-col>
            <el-col :span="3"      style=" display:flex;justify-content: flex-end;">
              <div   class="home_button "   :style="getStyleByBodyWd(12,22)"  @click=" getToPage('/services')" style=" display:flex;justify-content: flex-end;">
                <img alt="text" src="../assets/btn_left.svg"  :style="getStyleByBodyWd(12,22)" >
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "teamPage",
  data(){
    return{
      locator:0,
      catalogSelect:0,
      case_detail:-1,
      htmlWidth:document.getElementsByTagName('html')[0].clientWidth,
      htmlHeight:document.getElementsByTagName('html')[0].clientHeight,
      windowTurn:false,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.forceLandscapeScreenHandle();
      // 这里监控
      this.onWindowSizeChanged();
    },
    forceLandscapeScreenHandle() {
      const body = document.getElementsByTagName('body')[0];
      const html = document.getElementsByTagName('html')[0];
      const width = html.clientWidth;
      const height = html.clientHeight;
      this.windowTurn=html.clientWidth<html.clientHeight?true:false;
      const max = width > height ? width : height;
      const min = width > height ? height : width;
      body.style.width = max + "px";
      body.style.height = min + "px";
      this.htmlHeight=min;
      this.htmlWidth=max;
      this.waterfallImgWidth=this.htmlWidth/4.89;
      this.waterfallImgRight=this.htmlWidth/66;
      this.waterfallImgBottom=this.htmlWidth/66;
      this.mainStyle="height:"+min + "px;"+"width:"+max + "px";
      // for(let n=0;n<this.waterfallList.length;n++)
      // {
      //   var imgData =this.waterfallList[n];
      // }
    },
    onWindowSizeChanged() {
      window.addEventListener("resize", this.forceLandscapeScreenHandle);
    },
    getStyleByBodyWd(  w,h){
      if(w==0)
        return  "height:"+ this.htmlHeight/h + "px;";
      else if(h==0)
        return "width:"+this.htmlWidth/w+ "px;";
      else
        return  "height:"+ this.htmlHeight/h + "px;"+"width:"+this.htmlWidth/w+ "px;";
    },
    getToPage(val){
      this.$router.push(val);
    },
  },
}

</script>

<style scoped>
.container_bgImg_team
{
  background-size: 100%;
  background-repeat: no-repeat;
  background-position:center;
  background-image: url("../assets/team_background.webp");
}

</style>
