<template xmlns:el-col="http://www.w3.org/1999/html">

  <router-view/>
</template>

<script>













export default {




  data() {
    return {
      locator:0,
      catalogSelect:0,
      case_detail:-1,
      htmlWidth:document.getElementsByTagName('html')[0].clientWidth,
      htmlHeight:document.getElementsByTagName('html')[0].clientHeight,
      mainStyle:"",
      windowTurn:false,

    }
  },





  // beforeDestroy 废弃，使用 beforeUnmount
  beforeUnmount() {

  },
}

</script>

<style lang="less" >
#app {
  /*文字居中*/
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}

* {
  /*初始化样式*/
  margin: 0;
  padding: 0;
}

html {
  /*用于 获取 屏幕的可视宽高*/
  width: 100%;
  height: 100%;
  overflow: hidden;


}



</style>
