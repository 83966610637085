<template>
  <div  style="  background: rgba(0, 0, 0, 1); "  >
    <div   class="container_bgImg  "   :style="getStyleByBodyWd(1,1)"   >
      <el-row  class="home_main" >
        <!--  企业介绍    -->
            <div v-if="isHomeTransition" :style="getStyleByBodyWd(1,1)" class="home_transition_part" style="background-size:100%;"></div>
            <el-row v-else  class="flex_center" :style="getStyleByBodyWd(1,1)" >
              <el-col :class="windowTurn?'index_1_whole_padding_turn':'index_1_whole_padding'">
                <el-row    class="bottom_row_page_2  flex_center_row  " style="height:100%">
                  <!--  left part-->
                  <el-col :span="12"  style="text-align: left; display: flex;flex-direction: column;"  >
                    <div  :class="windowTurn?'left_top_zh_1_turn':'left_top_zh_1'">易客创新</div>
                    <div :class="windowTurn?'left_top_zh_2_turn':'left_top_zh_2'" >设计开发机构</div>
                    <div>
                      <div  :class="windowTurn?' left_top_en_turn   left_blue_margin_bom_turn  ':'left_top_en  left_blue_margin_bom  '"  class="en_font_black"  >EIKER Design And Development Agency</div>
                    </div>



                    <el-scrollbar :class="windowTurn?'company_info_turn':'company_info'" :style="getStyleByBodyWd(2.0,1.75)"   >
                      <div  :class="windowTurn?'company_info_text_2_turn':'company_info_text_2'">易客创新由一众志同道合、追梦未来的业内资深专家联合创办，是清华领军的领先务实型综合设计开发机构，专注于智能硬件领域的产品极致优化开发，拥有创新开拓型企业方阵：以色工业设计、艾奇方案设计、昆泰芯片设计，致力于为业内合作伙伴提供质优价廉的全方位高端极致服务，分享共赢，同创未来。
                        <!--                      <span class="company_info_text_2_strong">易客创新品牌设计</span>-->
                        <div>易客创新品牌设计 以品牌推广服务为核心，涵盖品牌全案设计、包装设计、视觉设计、视频设计、交互设计、合作推广六大创新服务模块。
                        </div>
                      </div>
                      <div  :class="windowTurn?'company_info_en_mobile':'company_info_en_web'" >EIKER, co-founded by a group of like-minded and future-oriented industry senior
                        experts, is a leading pragmatic comprehensive design and development institution led by
                        Tsinghua University. It focuses on the ultimate optimization and development of products in
                        the field of intelligent hardware. EIKER possesses an array of innovative and pioneering
                        enterprises, including ESER industrial design, iCheer solution design,  CONNTEK chip
                        design. It is dedicated to providing industry partners with high-end and ultimate services at
                        an excellent quality-to-price ratio, fostering a shared win-win mindset and co-creating the future.
                        <!--                      <span class="company_info_text_4_strong">  EIKER brand design</span> -->
                        <div>
                          The EIKER brand design is centered around brand promotion services, encompassing six
                          innovative service modules: brand comprehensive design, packaging design, visual
                          design, video design, interactive design, and collaborative promotion.
                        </div>
                      </div>

                    </el-scrollbar>
                    <!-- <div   class="home_button  "   :style="getStyleByBodyWd(12,22)"  @click="homeLeftButtonClick()" ><img alt="text" src="./assets/btn_right.svg" :style="getStyleByBodyWd(12,22)" >  </div> -->
                  </el-col>
                  <!--  right part              -->
                  <el-col :span="12" style="display: flex;justify-content: space-between;flex-direction: column; " :style="getStyleByBodyWd(2.0,1.155)" >
                    <!--                  左上角-->
                    <el-row :class="windowTurn?'right_top_page_2_turn':'right_top_page_2' "    >
                      <div>
                        <div    :class="windowTurn?'right_top_title_zh_page_2_turn':'right_top_title_zh_page_2'  "   >品牌发展部</div>
                        <div  :class="windowTurn?'right_top_title_en_page_2_turn':'right_top_title_en_page_2'  "  class="en_font_black"  >Brand Development Department</div>
                      </div>
                      <div    ><img alt="text" src="../assets/title_right_top.svg"  :class="windowTurn?'top_right_logo_turn':'top_right_logo'" ></div>
                    </el-row>
                    <el-row class="flex_center_row   " style="justify-content:space-between;align-items: center;flex-grow: 1;"  :class="windowTurn?'index_1_ball_turn':'index_1_ball'"  >

                      <div class="  move_ball_ring   "  style="display:flex;align-items:center;justify-content:center; flex-grow: 1;" >
                        <img    src="../assets/index_1_GIF1.webp"    :style="getStyleByBodyWd(0,1.12)" style="position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);" >
                      </div>

                      <!-- <div >
                        <div  >
                          <div  v-for=" (object,ii) in caseModels" :key="ii"   :class="windowTurn?'index_1_right_catalogs_info_turn':'index_1_right_catalogs_info'"
                          style="text-align: start;font-size: calc(100vw * 10 / 667);padding-top:calc(100vh * 3 / 375)">
                            {{object.title}}
                          </div>
                        </div>
                      </div> -->

                    </el-row>
                    <el-row style=" display:flex;justify-content: flex-end;"  >
                      <div  class="home_button  " :style="getStyleByBodyWd(12,22)"  @click="getToPage('/team')" style=" display:flex;justify-content: flex-end;">
                        <img alt="text" src="../assets/btn_left.svg"   :style="getStyleByBodyWd(12,22)" >
                      </div>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "companyPage",
  data(){
    return{
      locator:0,
      catalogSelect:0,
      case_detail:-1,
      htmlWidth:document.getElementsByTagName('html')[0].clientWidth,
      htmlHeight:document.getElementsByTagName('html')[0].clientHeight,
      windowTurn:false,
      isHomeTransition:false,
    }
  },
  mounted() {
    this.init();
    this.isHomeTransition=true;
    setTimeout(() => {
      this.isHomeTransition=false;
    }, 1000);
  },
  methods: {
    init() {
      this.forceLandscapeScreenHandle();
      // 这里监控
      this.onWindowSizeChanged();
    },
    forceLandscapeScreenHandle() {
      const body = document.getElementsByTagName('body')[0];
      const html = document.getElementsByTagName('html')[0];
      const width = html.clientWidth;
      const height = html.clientHeight;
      this.windowTurn=html.clientWidth<html.clientHeight?true:false;
      const max = width > height ? width : height;
      const min = width > height ? height : width;
      body.style.width = max + "px";
      body.style.height = min + "px";
      this.htmlHeight=min;
      this.htmlWidth=max;
      this.waterfallImgWidth=this.htmlWidth/4.89;
      this.waterfallImgRight=this.htmlWidth/66;
      this.waterfallImgBottom=this.htmlWidth/66;
      this.mainStyle="height:"+min + "px;"+"width:"+max + "px";
      // for(let n=0;n<this.waterfallList.length;n++)
      // {
      //   var imgData =this.waterfallList[n];
      // }
    },
    onWindowSizeChanged() {
      window.addEventListener("resize", this.forceLandscapeScreenHandle);
    },
    getStyleByBodyWd(  w,h){
      if(w==0)
        return  "height:"+ this.htmlHeight/h + "px;";
      else if(h==0)
        return "width:"+this.htmlWidth/w+ "px;";
      else
        return  "height:"+ this.htmlHeight/h + "px;"+"width:"+this.htmlWidth/w+ "px;";
    },
    getToPage(val){
      this.$router.push(val);
    },
  },
}
</script>

<style scoped>

</style>
