<template>
  <div  style="  background: rgba(0, 0, 0, 1); "  :style="getStyleByBodyWd(1,1)"   >
    <!-- 过渡动画 -->
    <div v-if="isCaseTransition&&locator==4" :style="getStyleByBodyWd(1,1)">
      <img src="../assets/case_transition.gif" style="width:100%;height:100%;object-fit:cover;">
    </div>
    <!-- 案例页面-->
    <div v-if="locator==4&&!isCaseTransition&&case_detail<0"   style="display: flex" :style="getStyleByBodyWd(1,1)">
      <!--   侧边栏       -->
      <div  :class= "asideBgImg[catalogSelect]"  :style="getStyleByBodyWd(15,1)"  >
        <el-col class=" aside_container_flex " style="height:100%;display: flex;align-items: center;justify-content: space-between;flex-direction: column;">
          <div :class="windowTurn?'aside_title_part_turn':'aside_title_part'" >
            <div :class="windowTurn?'aside_en_title_turn':'aside_en_title'" class="font_family_sy_balck"> {{this.caseModels[this.catalogSelect].title_en}}</div>
            <div :class="windowTurn?'aside_main_title_turn':'aside_main_title'" > {{this.caseModels[this.catalogSelect].title}}</div>
          </div>
          <div>
            <div>
              <el-col :span="24"  @click="rebackCatalogPage"   :class="windowTurn?'case_button_text_turn':'case_button_text'">
                <div><img src="../assets/分类icon.svg" :style="getStyleByBodyWd(32,0)"></div>
                <div>类别选择</div>
                <div class="font_family_sy_balck">Departments</div>
              </el-col>
            </div>
            <div>
              <el-col :span="24"  @click="refareshBtn"   :class="windowTurn?'case_button_text_turn case_refaresh_button_turn':'case_button_text case_refaresh_button'">
                <div><img src="../assets/刷新icon.svg" :style="getStyleByBodyWd(32,0)" ></div>
                <div>刷新页面</div>
                <div class="font_family_sy_balck">Refaresh</div>
              </el-col>
            </div>
          </div>
        </el-col>
      </div>
      <!--  右主页        -->
      <div :style="getStyleByBodyWd(1.07,1)"  style=" display:flex;align-items: end"  >
        <!--   加载         -->
        <div v-if="isLoading" class="loading_part">
          <img class="loading_part_img" src="../assets/loading.gif">
          <div class="loading_part_text">Loading ...</div>
        </div>
        <div v-else class="  row_flex_center_end">
          <!-- 案例导航  -->
          <div    :style="getStyleByBodyWd(1.16,1.048)"  class="row_flex_center_end "  >
            <!--                左侧-->
            <div :style="getStyleByBodyWd(4.92,1.048)"  class="  column_flex_between  ">
              <div :class="windowTurn?'case_title_turn':'case_title'"    >
                <div class="case_title_grad"  :class= "windowTurn?'case_left_title_1_turn '+caseTitleGrad[catalogSelect]:'case_left_title_1 '+caseTitleGrad[catalogSelect]">  {{this.subString_title(caseModels[catalogSelect].title_en,1)}}</div>
                <div class="case_title_grad"  :class= "windowTurn?'case_left_title_2_turn '+caseTitleGrad[catalogSelect]:'case_left_title_2  '+caseTitleGrad[catalogSelect]"> {{this.subString_title(caseModels[catalogSelect].title_en,2)}}</div>
                <div v-for="o_text in caseModels[catalogSelect].infos  " :key="o_text" :class="windowTurn?'case_info_line_turn':'case_info_line'">•{{o_text}}</div>
              </div>
              <div   :class="windowTurn?'case_catalog_left_logo_turn':'case_catalog_left_logo'" >
                <img :src="caseModels[catalogSelect].logo_x" :style="getStyleByBodyWd(0,33.75)">
              </div>
            </div>
            <!--     右侧           -->
            <div  :style="getStyleByBodyWd(1.38,1.048)"  style="display: flex;justify-content: center; " >
              <!--                  <or-waterfall   :html-height="htmlHeight" :html-width="htmlWidth" :window-turn="windowTurn" v-on:openCaseDetail="openCaseDetail"  :case_details="caseDetails"></or-waterfall>-->
              <el-scrollbar   :style="getCoverStyle()" style="display: flex;justify-content: center; "   ref="scrollbarRef"  id="scroll"   @scroll="scrollHandle"   >
                <div :key="waterfall_key"  class="v-waterfall-content    " id="v-waterfall"   :style="getStyleByBodyWd(1.5,1.06) " >
                  <div v-for="(img,index) in this.waterfallList" :key="index" class="v-waterfall-item    "   :style="{top:getWaterfall_top(img.id)+'px',left:getWaterfall_left(img.id)+'px',width:waterfallImgWidth+'px',height:img.height}" >
                    <div    :id="'map_card'+img.id" class="case_card home_button   " style="width: 90%;height: 95%"  @click=" openCaseDetail(img.id)">
                      <div   style="display: flex;justify-content: center;">
                      </div>
                      <img    v-lazy="img.src"  class=" case_card_main_img"/>
                      <div v-if="catalogSelect==6 "   :style="'height:' +this.waterfallImgWidth/3+'px'" style="display: flex;flex-direction: column;justify-content: space-around;align-items: start;" >
                        <div    :class="windowTurn?'case_6_card_title_turn':' case_6_card_title'"  :style="getStyleByBodyWd(0,26)" class="font_family_sy_balck" >{{img.title}}   </div>
                        <div    :class="windowTurn?'case_card_text_turn':' case_card_text'"    style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;width: 90%;">{{caseDetails[catalogSelect][img.id].text}} </div>
                      </div>
                      <div v-else style="display: flex;justify-content: center;align-items: center;" :style="windowTurn?getStyleByBodyWd(0,18):getStyleByBodyWd(0,26)" >
                        <div    :class="windowTurn?'card_title_turn':' case_card_title'"
                                style="width: 90%;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; text-align: center;"
                                :style="getStyleByBodyWd(0,26)+'display: flex;justify-content: center;align-items: center;'" >   {{img.title}}</div>
                      </div>

                    </div>

                  </div>
                </div>
              </el-scrollbar>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>

</template>

<script>
export default {
  name: "waterFall",
  data() {
    return {
      locator:4,
      catalogSelect:0,
      case_detail:-1,
      htmlWidth:document.getElementsByTagName('html')[0].clientWidth,
      htmlHeight:document.getElementsByTagName('html')[0].clientHeight,
      windowTurn:false,
      catalog_bg:['catalog_bg_img_0','catalog_bg_img_1','catalog_bg_img_2','catalog_bg_img_3','catalog_bg_img_4','catalog_bg_img_5','catalog_bg_img_1'],
      asideBgImg:['aside-img0','aside-img1','aside-img2','aside-img3','aside-img4','aside-img5','aside-img1'],
      caseTitleGrad:['case_grad_color_0',"case_grad_color_1","case_grad_color_2","case_grad_color_3","case_grad_color_4","case_grad_color_5","case_grad_color_1"],
      caseModels:this.$caseModels,
      caseShow:[],
      caseDetails: this.$caseDetails,
      videoCaseIndex:0,
      case_index:0,
      isLoading:false,
      isCaseTransition:false,
      initialIndex:0,
      //waterfall
      waterfallImgCol: 3,// 瀑布流的列数
      waterfallImgWidth: 0,// 每个盒子的宽度
      waterfallImgRight: 0,// 每个盒子的右padding
      waterfallImgBottom:0,// 每个盒子的下padding
      waterfallDeviationHeight: [],
      imgList: [],
      waterfallList: [],
      waterfall_key:0,
      waterfall_model:[
        {
          src:this.$mediaURL+'/case_2/cover/01.webp',
          width:0,
          height:0,
        },
        {
          src:this.$mediaURL+'/case_1/cover/02.webp',
          width:0,
          height:0,
        },

      ],
      url_list:[
        "Brand_Design",
        "Visual_Design",
        "Package_Design",
        "Video_Design",
        "User_Experience ",
        "Cooperative_Promotion",
      ],
      scrollTop:0,//滚动距离
    }
  },
  created() {
    if(localStorage.getItem("scrollbarTop")!=null)
    {
      this.scrollTop= Number(localStorage.getItem("scrollbarTop"));

    }
  },
  mounted() {

    var timeout = 3600000;
    if(localStorage.getItem("lastTime")!=null && Date.now() -localStorage.getItem("lastTime")<timeout )
    {
      console.log("没超");
      if(localStorage.getItem("locator")==4)
      {
        this.locator=localStorage.getItem("locator");
        this.catalogSelect=localStorage.getItem("catalogSelect");
      }

    }else
    {
      localStorage.removeItem("locator");
      localStorage.removeItem("catalogSelect");
      this.$store.commit('set_lastTime');
    }
   if(localStorage.getItem("catalogSelect")!=null)
   {
     this.catalogSelect=localStorage.getItem("catalogSelect");
   }else
   {
     for (var m=0;m<this.caseModels.length;m++)
     {
       var title = this.$route.params.title;

       if (title == this.caseModels[m].title_en.replace(/\s/g,"_"))
       {
         this.catalogSelect=m;
         break;
       }
     }
   }
    this.init();
     this.imgModelPreloading();
    this.calculationWidth();
    //在页面dom渲染完成后弹出弹窗‘‘我来的刚刚好吧’’
//在3000毫秒后跳出弹窗‘‘对不起, 要你久候’’

    setTimeout(this.scrollTest, 200 )


  },

  methods: {
    init() {
      this.forceLandscapeScreenHandle();
      // 这里监控
      this.onWindowSizeChanged();
    },
    getToPage(val){
      this.$router.push(val);
    },
    //图片预加载
    scrollTest(){
      this.$refs.scrollbarRef.setScrollTop(this.scrollTop);
    },
    imgCoverPreloading() {
      for (let i = 0; i < this.caseDetails.length; i++) {

        let aImg = new Image();
        aImg.src =this.caseDetails[i][1].cover;
        aImg.onload = aImg.onerror = () => {
          let imgData = {};
          imgData.orheight=aImg.height;
          imgData.orwidth=aImg.width;
          imgData.height = this.waterfallImgWidth / aImg.width * aImg.height;
          imgData.src =this.caseDetails[i][1].cover;
          imgData.title = this.caseDetails[i][1].title;// 说明文字（也可以自己写数组，或者封装json数据，都可以，但是前提是你会相关操作，这里不赘述）
          imgData.id=i;

        }
      }



    },
    async openCaseDetail(value) {

      this.case_detail = value;
      localStorage.setItem('scrollbarTop',this.scrollTop);
      var url=  '/services/'+this.caseModels[this.catalogSelect].title_en.replace(/\s/g,"_")+'/'+this.caseDetails[this.catalogSelect][value].title_en;
      localStorage.setItem('case_detail_id',value);
      this.$router.push(url);

// 接收参数

      this.caseShow=this.caseDetails[this.catalogSelect];

      this.$forceUpdate();

    },

    change_carousel(type){
      this.$refs['carousel'][type]()
    },

    subString_title(S,line)
    {
      var str1=S.substring(0, S.indexOf(" "));
      var str2=S.substring(str1.length+1, S.length);
      if(line==1) return str1;
      if(line==2) return  str2;
    },
    //滚动回调
    scrollHandle(scrollVale ){
       this.scrollTop=scrollVale.scrollTop;

    },
    forceLandscapeScreenHandle() {
      const body = document.getElementsByTagName('body')[0];
      const html = document.getElementsByTagName('html')[0];
      const width = html.clientWidth;
      const height = html.clientHeight;
      this.windowTurn=html.clientWidth<html.clientHeight?true:false;
      const max = width > height ? width : height;
      const min = width > height ? height : width;
      body.style.width = max + "px";
      body.style.height = min + "px";
      this.htmlHeight=min;
      this.htmlWidth=max;
      this.waterfallImgWidth=this.htmlWidth/4.89;
      this.waterfallImgRight=this.htmlWidth/66;
      this.waterfallImgBottom=this.htmlWidth/66;
      this.mainStyle="height:"+min + "px;"+"width:"+max + "px";
      console.log(html.clientWidth/html.clientHeight);
    },

    onWindowSizeChanged() {
      window.addEventListener("resize", this.forceLandscapeScreenHandle);
    },
    getStyleByBodyWd(  w,h){
      if(w==0)
        return  "height:"+ this.htmlHeight/h + "px;";
      else if(h==0)
        return "width:"+this.htmlWidth/w+ "px;";
      else
        return  "height:"+ this.htmlHeight/h + "px;"+"width:"+this.htmlWidth/w+ "px;";
    },
    getCoverStyle(){
      var line =   Math.floor((this.htmlHeight/1.1)/(this.waterfallImgWidth+this.waterfallImgRight) ) ;
      if(line>1){
        if((this.htmlHeight/1.1)/(this.waterfallImgWidth+this.waterfallImgRight)-line<0.4)
        {
          this.waterfallImgWidth=this.htmlWidth/4.8;
          this.waterfallImgRight=this.htmlWidth/60;
          this.waterfallImgBottom=this.htmlWidth/60;
          return  "height:"+ line*(this.waterfallImgWidth*1.1+this.waterfallImgRight) + "px;";
        }

      }
     return  this.getStyleByBodyWd(1.38,1.1)

    },
    homeRightButtonClick(){
      if(this.locator<4)
        this.locator=this.locator+1;
      this.$store.commit('set_locator_info',this.locator);
      if(this.locator==1){
        this.isHomeTransition=true;
        setTimeout(() => {
          this.isHomeTransition=false;
        }, 1000);
      }
    },
    homeLeftButtonClick(){
      if(this.locator>0){
        if(this.locator==2){
          this.locator=0
        }
        else{
          this.locator=this.locator-1;
        }
      }
      this.$store.commit('set_locator_info',this.locator);
    },
    caseCatalogChange(index,  titles){
      var   s = titles.replace(/\s/g,"_");
      var url= encodeURI('/services/'+ s  )  ;
      this.$router.push(url);
      this.locator=4;
      this.catalogSelect=index;
      this.locator=4;
      this.catalogSelect=index;
      this.$store.commit('set_locator_info',this.locator);
      this.$store.commit('set_case_info',this.catalogSelect);
      this.case_index=0;
      this.videoCaseIndex=0;
      this.isCaseTransition=true;
      setTimeout(() => {
        this.isCaseTransition=false;
      }, 400);
      this.waterfallList=[];
      this.imgPreloading();
      this.refareshBtn();
      this.waterfall_key++;
      // location.reload();
    },
    rebackCatalogPage(){
      this.locator=3;
      localStorage.setItem('scrollbarTop',null);
      console.log("clear___scrollbarTop");
      this.getToPage('/services');
    },

    refareshBtn(){

      this.isLoading=true;
      setTimeout(() =>{
        this.$forceUpdate();
        this.isLoading=false;
        this.case_detail=-1;
      }, 1600);
    },
    //计算每个图片的宽度或者是列数
    calculationWidth() {
      let domWidth=this.htmlWidth/1.4;
      // let domWidth = document.getElementById("v-waterfall").offsetWidth;
      if (!this.waterfallImgWidth && this.waterfallImgCol) {
        this.waterfallImgWidth = (domWidth - this.waterfallImgRight * this.waterfallImgCol) / this.waterfallImgCol;
      } else if (this.waterfallImgWidth && !this.waterfallImgCol) {
        this.waterfallImgCol = Math.floor(domWidth / (this.waterfallImgWidth + this.waterfallImgRight))
      }
      //初始化偏移高度数组
      this.waterfallDeviationHeight = new Array(this.waterfallImgCol);
      for (let i = 0; i < this.waterfallDeviationHeight.length; i++) {
        this.waterfallDeviationHeight[i] = 0;
      }
      this.imgPreloading();
      this.waterfall_key++;
      this.imgCoverPreloading();
    },
    //图片预加载
    imgPreloading() {
      for (let i = 0; i < this.caseDetails[this.catalogSelect].length; i++) {

        let aImg = new Image();
        aImg.src =this.caseDetails[this.catalogSelect][i].cover;
        aImg.onload = aImg.onerror = () => {
          let imgData = {};
          imgData.orheight=aImg.height;
          imgData.orwidth=aImg.width;
          imgData.height = this.waterfallImgWidth / aImg.width * aImg.height;
          imgData.src =this.caseDetails[this.catalogSelect][i].cover;
          imgData.title = this.caseDetails[this.catalogSelect][i].title;
          imgData.id=i;
          imgData.top=0;
          this.waterfallList.push(imgData);


          // this.rankImg(imgData,i);
        }
      }
    },
    //waterfall stand
    imgModelPreloading() {
      for (let i = 0; i < this.waterfall_model.length; i++) {

        let aImg = new Image();
        aImg.src = this.waterfall_model[i].src;
        aImg.onload = aImg.onerror = () => {
          this.waterfall_model[i].height=aImg.height;
          this.waterfall_model[i].width= aImg.width;
        }
      }
    },
    getWaterfall_top(id)
    {
      const html = document.getElementsByTagName('html')[0];
      // console.log("top",id,top);
      if(id<3)
      {
        //第一行 top=0；
        this.waterfallList[id].top=0;
        return 0;
      }else
      {
        var  padding_s = this.windowTurn?0.22:0.14;

        var top =0;
        if(this.catalogSelect==6)
        {
          // 合作推广的边距计算
          top= this.waterfallList[id-3].top +this.waterfallImgWidth*1+this.waterfallImgRight;
        }else if(this.catalogSelect==3 || this.catalogSelect==5 )
        {
          //视觉设计&&展会的封面边距计算
          if(html.clientWidth/html.clientHeight<1&&html.clientWidth/html.clientHeight>0.7)
          {
            top= this.waterfallList[id-3].top +  this.waterfallImgWidth*1.112+this.waterfallImgRight*3;
          }else{
            top= this.waterfallList[id-3].top +  this.waterfallImgWidth*1.112+this.waterfallImgRight;
          }

        }
        else
        {
          if(this.caseDetails[this.catalogSelect][id-3].shape==0)
          {
            top = this.waterfallList[id-3].top+ (this.waterfallImgWidth /this.waterfall_model[0].width* this.waterfall_model[0].height)+ (this.waterfallImgWidth /this.waterfall_model[0].width* this.waterfall_model[0].height)*padding_s;

          }else
          {
            top =this.waterfallList[id-3].top + (this.waterfallImgWidth /this.waterfall_model[1].width* this.waterfall_model[1].height)+ (this.waterfallImgWidth /this.waterfall_model[0].width* this.waterfall_model[0].height)*(padding_s-0.01);

          }
        }
        this.waterfallList[id].top = top;

        return  top ;
      }
    },
    getWaterfall_left( id)
    {
      return (id%3 ) * (this.waterfallImgRight + this.waterfallImgWidth);
    },
    //瀑布流布局
    rankImg(imgData,i) {
      let {
        waterfallImgWidth,
        waterfallImgRight,
        waterfallImgBottom,
        // waterfallDeviationHeight,
        // waterfallImgCol,
      } = this;
      // let minIndex = this.filterMin();
      if(Math.floor(imgData.id/3)>0)
      {
        imgData.top =this.waterfallList[i-3].top+ this.waterfallList[i-3].height+ waterfallImgBottom +this.htmlHeight/14 ;
      }else
      {
        imgData.top = Math.floor(imgData.id/3);
      }

      imgData.left = (imgData.id%3 ) * (waterfallImgRight + waterfallImgWidth);
      // waterfallDeviationHeight[minIndex] += imgData.height + waterfallImgBottom;// 不加文字的盒子高度

    },
    /**
     * 找到最短的列并返回下标
     * @returns {number} 下标
     */
    filterMin() {
      const min = Math.min.apply(null, this.waterfallDeviationHeight);

      return this.waterfallDeviationHeight.indexOf(min);
    },
    overHiddenText( s,n){

      if(s.length>n)
      {
        return   s.substr(0,n)+'...';
      }else
      {
        return s;
      }
    },
    imgsPreload( type, imgs){
      if(type==2)
      {
        for(let img of imgs)
        {

          let image =new Image();
          image.src  =img;

          image.onload=()=>{
          }
        }
      }else if(type==0)
      {
        let image =new Image();
        image.src  =imgs;
        image.onload=()=>{
        }
      }

    },

  },
}
</script>

<style scoped>

</style>
