<template>
    <div  style="  background: rgba(0, 0, 0, 1); "  >
      <div   class="container_bgImg bg-img0"   :style="getStyleByBodyWd(1,1)"   >
        <el-row  class="home_main" >
          <!-- 首页locator_0 -->
            <el-row  class="  row_flex_between " :style="getStyleByBodyWd(1,1)" :class="windowTurn?'home_whole_padding_turn':'home_whole_padding'"  >
              <el-col :span="12"  :class="windowTurn?'page_0_left_part_turn':'page_0_left_part' "  :style="getStyleByBodyWd(0,1)"  >
                <div  :class="windowTurn?'page_0_title_block_1_turn':'page_0_title_block_1'">
                  <div  :class="windowTurn?'page_0_title_noto_balck_turn':'page_0_title_noto_balck' " >品牌发展部</div>
                  <div  :class="windowTurn?'page_0_title_noto_sc_blod_turn':'page_0_title_noto_sc_blod' " >服务介绍</div>
                  <div  :class="windowTurn?'page_0_title_block_2_turn':'page_0_title_block_2'" >
                    <div> EIKER </div>
                    <div>Brand Development</div>
                    <div>Department</div>
                  </div>
                </div>
                <div :class="windowTurn?'page_0_title_block_3_turn':'page_0_title_block_3'" >
                  <div   >
                    <img  :class="windowTurn?'home_logo_img_turn':'home_logo_img'" src="../assets/eiker_logo.svg">
                  </div>
                </div>
              </el-col>
              <el-col :span="12"  :class="windowTurn?'page_0_right_part_turn':'page_0_right_part'    " :style="getStyleByBodyWd(2,1)">
                <el-row   >
                  <el-col  :span="12" style="display:flex;justify-content:flex-end;">
                    <el-row  class="flex_center_row   " >
                      <div :class="windowTurn?'home_brand_done_div_turn':'home_brand_done_div'"  class="  flex_center"><img src="../assets/brand_doe.png" :class="windowTurn?'home_brand_done_turn':'home_brand_done'"></div>
                      <div  :class="windowTurn?'home_brand_text_turn':'home_brand_text' "  >Brand design</div>
                      <div :class="windowTurn?'home_brand_done_div_turn':'home_brand_done_div'"  class="flex_center"><img src="../assets/brand_doe.png"  :class="windowTurn?'home_brand_done_turn':'home_brand_done'"></div>
                    </el-row>
                  </el-col>
                  <el-col  :span="12">
                    <div  v-for=" (object,ii) in caseModels" :key="ii"    :class="windowTurn?'home_ad_text_turn':'home_ad_text'" >
                      {{object.title}}
                    </div>
                  </el-col>

                </el-row>
                <el-row   class=" flex_row_space-between" :style="getStyleByBodyWd(2,0)">
                  <el-col :span="18"></el-col>
                  <el-col  :span="6"  >
                    <div   class="home_button   "    :style="getStyleByBodyWd(12,22)"  @click="getToPage()" ><img alt="text" src="../assets/btn_left.svg" :style="getStyleByBodyWd(12,22)" >  </div>

                  </el-col>
                </el-row>
                <el-row   style=" display:flex;justify-content: right;  flex-direction: row;align-items: center;">
                  <el-col >
                    <div  :class="windowTurn?'copyright_text_turn':'copyright_text'" class="font_family_sy_balck">Copyright@2023 易客创新案例手册 | 全部产品版权归易客创新有限公司所有 </div>
                    <div   :class="windowTurn?'copyright_text_turn':'copyright_text'"   class="font_family_sy_balck" >技术版权归易客创新（泉州）智能科技有限公司所有 | 闽ICP备19023234号-2   </div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
        </el-row>
      </div>
    </div>
</template>

<script>
export default {
  name: "IndexPage",
  data() {
    return {
      locator:0,
      catalogSelect:0,
      case_detail:-1,
      htmlWidth:document.getElementsByTagName('html')[0].clientWidth,
      htmlHeight:document.getElementsByTagName('html')[0].clientHeight,
      windowTurn:false,

    }
  },
  mounted() {
    var timeout = 3600000;
    if(localStorage.getItem("lastTime")!=null && Date.now() -localStorage.getItem("lastTime")<timeout )
    {
      console.log("没超");
      if(localStorage.getItem("locator")==4)
      {
        this.locator=localStorage.getItem("locator");
        this.catalogSelect=localStorage.getItem("catalogSelect");
      }

    }else
    {
      localStorage.removeItem("locator");
      localStorage.removeItem("catalogSelect");
      this.$store.commit('set_lastTime');
    }


    this.init();
    //--waterfall-//
    // this.imgModelPreloading();
    // this.calculationWidth();
    // this.imgCoverPreloading();
  },
  methods: {
    init() {
      this.forceLandscapeScreenHandle();
      // 这里监控
      this.onWindowSizeChanged();
    },
    forceLandscapeScreenHandle() {
      const body = document.getElementsByTagName('body')[0];
      const html = document.getElementsByTagName('html')[0];
      const width = html.clientWidth;
      const height = html.clientHeight;
      this.windowTurn=html.clientWidth<html.clientHeight?true:false;
      const max = width > height ? width : height;
      const min = width > height ? height : width;
      body.style.width = max + "px";
      body.style.height = min + "px";
      this.htmlHeight=min;
      this.htmlWidth=max;
      this.waterfallImgWidth=this.htmlWidth/4.89;
      this.waterfallImgRight=this.htmlWidth/66;
      this.waterfallImgBottom=this.htmlWidth/66;
      // this.mainStyle="height:"+min + "px;"+"width:"+max + "px";
      // for(let n=0;n<this.waterfallList.length;n++)
      // {
      //   var imgData =this.waterfallList[n];
      // }
    },
    onWindowSizeChanged() {
      window.addEventListener("resize", this.forceLandscapeScreenHandle);
    },
    getStyleByBodyWd(  w,h){
      if(w==0)
        return  "height:"+ this.htmlHeight/h + "px;";
      else if(h==0)
        return "width:"+this.htmlWidth/w+ "px;";
      else
        return  "height:"+ this.htmlHeight/h + "px;"+"width:"+this.htmlWidth/w+ "px;";
    },
    getToPage(){
      this.$router.push('/team');
    },
  },
}
</script>

<style scoped>
.exhibition_index{
  left: 0px;
  top: 0px;
  width:  100%;
  /*height: 375px;*/
  opacity: 1;
  background: rgba(0, 0, 0, 1);

}


* {
  /*初始化样式*/
  margin: 0;
  padding: 0;
}

html {
  /*用于 获取 屏幕的可视宽高*/
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: url("../assets/index_0_background.webp");

}

body {
  /*让 body 初始 width 和 height 就 等于 页面可视区域的 宽高*/
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

}

@media screen and (orientation: portrait) {
  /*竖屏样式*/
  body {
    transform-origin: 0 0;
    transform: rotateZ(90deg) translateY(-100%);
  }
}

</style>

<style lang="less" >
#app {
  /*文字居中*/
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}

* {
  /*初始化样式*/
  margin: 0;
  padding: 0;
}

html {
  /*用于 获取 屏幕的可视宽高*/
  width: 100%;
  height: 100%;
  overflow: hidden;


}

body {
  /*让 body 初始 width 和 height 就 等于 页面可视区域的 宽高*/
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;




}

@media screen and (orientation: portrait) {
  /*竖屏样式*/
  body {
    transform-origin: 0 0;
    transform: rotateZ(90deg) translateY(-100%);
  }
}
/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: "思源黑体 Normal";

  src: url("//at.alicdn.com/wf/webfont/GP1RyVf8SvFw/ai81Uk3UDG3h.woff2") format("woff2"),
  url("//at.alicdn.com/wf/webfont/GP1RyVf8SvFw/FlDwLLUgK8xC.woff") format("woff");
  font-display: swap;
}
/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: "思源宋体 Bold";
  font-weight: 700;

  src: url("//at.alicdn.com/wf/webfont/GP1RyVf8SvFw/9nmLszVCkdwe.woff2") format("woff2"),
  url("//at.alicdn.com/wf/webfont/GP1RyVf8SvFw/zoSuQToVmhTm.woff") format("woff");
  font-display: swap;
}
.bg-img0 {
  background-size:100%;
  background: url("../assets/index_0_background.webp") center no-repeat;
}



// .aside-img0,.aside-img1,.aside-img2,.aside-img3,.aside-img4,.aside-img5{
//   display: flex;
// }
.aside-img0 {
  background-size: 100% 100%;
  overflow: hidden;
  background: -webkit-linear-gradient(bottom,hsla(238,73%,46%,1),hsla(238,73%,46%,1),hsla(150,81%,60%,1))  no-repeat;
}

.aside-img1 {
  background-size: 100% 100%;
  overflow: hidden;

  background: -webkit-linear-gradient(bottom,hsla(238,73%,46%,1),hsla(238,73%,46%,1),hsla(297,81%,59%,1))  no-repeat;
}
.aside-img2 {
  background-size: 100% 100%;
  overflow: hidden;

  background: -webkit-linear-gradient(bottom,hsla(238,73%,46%,1),hsla(238,73%,46%,1),hsla(11,95%,57%,1))  no-repeat;
}
.aside-img3 {
  background-size: 100% 100%;
  overflow: hidden;

  background: -webkit-linear-gradient(bottom,hsla(238,73%,46%,1),hsla(238,73%,46%,1),hsla(193,93%,56%,1))  no-repeat;
}
.aside-img4 {
  background-size: 100% 100%;
  overflow: hidden;

  background: -webkit-linear-gradient(bottom,hsla(238,73%,46%,1),hsla(238,73%,46%,1),hsla(258,79%,60%,1))  no-repeat;
}
.aside-img5 {
  background-size: 100% 100%;
  overflow: hidden;
  background: -webkit-linear-gradient(bottom,hsla(238,73%,46%,1),hsla(238,73%,46%,1),hsla(185,100%,49.6%,1))  no-repeat;
}
.case_2_bg-img1 {
  background-size:100%;

}
.container_bgImg
{
  background-size: 100%;
  background-repeat: no-repeat;
  background-position:center;
}
.brand_blue_text{
  ont-size:calc(100vh * 8 / 375);
  font-weight: 400;
  letter-spacing: calc(100vh * 1 / 375);
  line-height:calc(100vh * 11.58 / 375);
  color: rgba(77, 85, 255, 1);
  text-align: justify;
}

.row_flex_between{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}
.row_flex_center_end{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  width: 100%;
}

.home_whole_padding{
  padding-right: calc(100vh * 30 / 375);
  padding-left: calc(100vh *30 / 375);
  text-align: start;
}
.home_whole_padding_turn{
  padding-right: calc(100vw * 30 / 375);
  padding-left: calc(100vw *30 / 375);
  text-align: start;
}
.page_0_title_noto_balck
{
  // font-family: '思源黑体 Normal';
  font-size: calc(100vw * 30/ 667);
  font-weight: 400;
  letter-spacing: calc(100vh * 2 / 375);
  line-height: calc(100vh * 35 / 375);
  color: rgba(255, 255, 255, 1);
}
.page_0_title_noto_balck_turn
{
  font-family: '思源黑体 Normal';
  font-size: calc(100vh * 30/ 667);
  font-weight: 400;
  text-align: left;
  letter-spacing: calc(100vw * 2 / 375);
  line-height: calc(100vw * 35 / 375);
  color: rgba(255, 255, 255, 1);
}
.page_0_title_noto_sc_blod{
  font-family: '思源宋体 Bold';
  text-align: left;
  margin-left:calc(100vw * -2.5 / 375);
  font-size: calc(100vw * 56 / 667);
  letter-spacing: calc(100vh * 2 / 375);
  padding-top: calc(100vw * -6/ 375);
  padding-bottom: calc(100vh * 14 / 375);
  line-height: calc(100vw * 40/ 375);
}
.page_0_title_noto_sc_blod_turn{
  font-family: '思源宋体 Bold';
  text-align: left;
  margin-left:calc(100vh *-2.5 / 375);
  font-size: calc(100vh * 56 / 667);
  letter-spacing: calc(100vw * 2 / 375);
  padding-top: calc(100vh * -4/ 375);
  padding-bottom: calc(100vh * 10 / 375);
  line-height: calc(100vh * 40/ 375);
}
.page_0_title_block{
  letter-spacing: 2px;
  font-size: calc(100vh * 14 / 667)
}
.page_0_title_block_1{
  width:calc(100vh * 357 / 375);
  height: calc(100vh * 121 / 375);
  text-align: start;
}
.page_0_title_block_1_turn{
  width:calc(100vw * 357 / 375);
  height: calc(100vw * 121 / 375);
  text-align: start;
}
.page_0_title_block_2{
  margin-top:calc(100vh *24 / 375);
  font-family: '思源黑体 Normal';
  //width: calc(100vh * 113 / 375);
  height: calc(100vh * 55 / 375);
  text-align: start;
  /** 文本1 */
  font-weight: 400;
  font-size: calc(100vw * 29 / 1920);
  letter-spacing: calc(100vh * 1 / 375);
  line-height: calc(100vw * 36 / 1920);
  color: rgba(255, 255, 255, 1);
}
.page_0_title_block_2_turn{
  margin-top:calc(100vw *24 / 375);
  font-family: '思源黑体 Normal';
  //width: calc(100vw * 113 / 375);
  height: calc(100vw * 55 / 375);
  text-align: start;
  /** 文本1 */
  font-size: calc(100vh * 29 / 1920);
  letter-spacing: calc(100vw * 1 / 375);
  line-height: calc(100vh * 36 / 1920);
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
}
.page_0_title_block_3{
  display: flex;
  align-items: center;
  justify-content: start;
  // margin-bottom: calc(100vh *10 / 375);
}
.page_0_title_block_3_turn{
  display: flex;
  align-items: center;
  justify-content: start;
  // margin-bottom: calc(100vw *10 / 375);
}
.page_0_title_block_3 .home_logo_img,.page_0_title_block_3_turn .home_logo_img_turn{
  display: flex;
  align-items: center;
}
.page_0_left_part{

  display: flex;
  flex-direction:column;
  justify-content: space-between;
  padding-top: calc(100vh * 40 / 375);
  padding-bottom: calc(100vh *30/ 375);
}
.page_0_left_part_turn{
  display: flex;
  flex-direction:column;
  justify-content: space-between;
  padding-top: calc(100vw * 40 /375);
  padding-bottom: calc(100vw *30/ 375);
}
.column_flex_between{

  display: flex;
  flex-direction:column;
  justify-content: space-between;
}
.page_0_right_part{
  padding-top: calc(100vh * 40 / 375);
  padding-bottom: calc(100vh * 30 / 375);

  display: flex;
  flex-direction:column;
  justify-content: space-between;
  //align-items: end;
}
.left_blue_margin_bom{
  margin-bottom: calc(100vh * 180 / 1080);
}

.left_blue_margin_bom_turn{
  margin-bottom: calc(100vw * 88 / 1080);
}
.left_case_blue_turn{
  margin-bottom: calc(100vw * 30 / 1080);
}
.left_case_blue{
  margin-bottom: calc(100vh * 30 / 1080);
}
.page_0_right_part_turn{
  padding-top: calc(100vw * 40 / 375);
  padding-bottom: calc(100vw * 30 / 375);

  display: flex;
  flex-direction:column;
  justify-content: space-between;
  //align-items: end;
}
.home_logo_title{
  align-items: end;



}
.home_logo_title_text_1{
  text-align: justify;
  text-align-last: justify;
  width: calc(100vw * 120/ 1920);
  font-size:  calc(100vw * 10 / 667);
}
.home_logo_title_text_1_turn{
  text-align: justify;
  //text-align-last: justify;
  width: calc(100vh * 122 / 1920);
  font-size:  calc(100vh * 10 / 667);
}
.home_logo_title_text_2{
  text-align: start;
  //text-align-last: justify;

  font-size: calc(100vw * 7 / 667);
}
.home_logo_title_text_2_turn{
  text-align: start;
  font-size: calc(100vh * 7 / 667);
}
.home_main{
  display: flex;
  justify-content: start;
  align-items: center;
}
.container_border{
  border-radius:calc(100vh * 5 / 375);
  border: calc(100vh * 0.5 / 375) solid rgba(255, 255, 255, 1);
  border-color: white;
}

.container_border_debug{
  //border-radius: calc(100vh * 5 / 375);
  border: 0.5px solid rgba(255, 255, 255, 1);
  border-color: red;
}
.home_logo_img
{
  //.container_border;
  height: calc(100vw * 58 / 1920);
  width: calc(100vw * 180 / 1920);
}
.home_logo_img_turn
{
  height: calc(100vh * 58 / 1920);
  width: calc(100vh * 180 / 1920);

}
.home_button{
  opacity: 1;
  cursor: pointer; /*悬停变小手的属性*/
}
.copyright_text{

  // height: calc(100vh * 21 / 375);
  opacity: 1;
  /** 文本1 */
  font-size: calc(100vw * 7 / 667);
  font-weight: 400;
  letter-spacing: calc(100vh * 0 / 375);
  line-height: calc(100vh * 10.14 / 375);
  color: rgba(255, 255, 255, 1);
  text-align: right;
  vertical-align: top;
}
.copyright_text_turn{

  // height: calc(100vw * 21 / 375);
  opacity: 1;
  /** 文本1 */
  font-size: calc(100vh * 7 / 667);
  zoom: 0.95;
  font-weight: 400;
  letter-spacing: calc(100vw * 0 / 375);
  line-height: calc(100vw * 10.14 / 375);
  color: rgba(255, 255, 255, 1);
  text-align: right;
  vertical-align: top;
}
.home_brand_text{
  font-size: calc(100vw * 8 / 667);
  font-weight: 400;
  letter-spacing: calc(100vh * 0/ 375);
  line-height: calc(100vh * 14 / 375);
  color: rgba(255, 255, 255, 1);
  text-align: center;
  vertical-align: top;
  padding-right: calc(100vh * 2 / 375);
  padding-left: calc(100vh * 2 / 375);
}
.home_brand_done_div{
  height: calc(100vh * 14/ 375);
  width: calc(100vh * 8 / 375);
}
.home_brand_done_div_turn{
  height: calc(100vw * 14 / 375);
  width: calc(100vw * 8 / 375);
  line-height: calc(100vw * 10 / 375);
}
.home_brand_done_div,.home_brand_done_div_turn{
  display: flex;
  align-items: center;
  justify-content: center;
}
.home_brand_done{
  width: calc(100vh * 4 / 375);
  height: calc(100vh * 4 / 375);
}
.home_brand_done_turn{
  width: calc(100vw * 4 / 375);
  height: calc(100vw * 4 / 375);

}
.home_brand_text_turn{
  font-size: calc(100vh * 8 / 667);
  font-weight: 400;
  letter-spacing: calc(100vw * 0/ 375);
  line-height: calc(100vw * 14 / 375);
  color: rgba(255, 255, 255, 1);
  text-align: center;
  vertical-align: top;
  padding-right: calc(100vw * 2 / 375);
  padding-left: calc(100vw * 2 / 375);
}
.home_ad_text{
  font-family: '思源黑体 Normal';
  font-size:calc(100vw * 10 / 667);
  font-weight: 400;
  letter-spacing: calc(100vh * 1 / 375);
  line-height:calc(100vh * 16 / 375);
  color: rgba(255, 255, 255, 1);
  text-align: right;
  vertical-align: top;
}
.home_ad_text_turn{
  font-family: '思源黑体 Normal';
  font-size:calc(100vh * 10 / 667);
  font-weight: 400;
  letter-spacing: calc(100vw * 1 / 375);
  line-height:calc(100vw * 16 / 375);
  color: rgba(255, 255, 255, 1);
  text-align: right;
  vertical-align: top;
}
.home_transition_part{
  background-size:100%;
  background: url("../assets/home_transition.gif") center no-repeat;
}
.row_whole_page_2{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.bottom_row_page_2{

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // align-items: center;

}
.right_top_title_en_page_2{
  //.container_border;
  //height: calc(100vh *11 / 375);
  opacity: 1;
  text-shadow: calc(100vh * 0 / 375) calc(100vh * 1 / 375) calc(100vh * 2 / 375)  rgba(0, 0, 0, 0.5);
  /** 文本1 */
  font-size: calc(100vw * 8 / 667);
  font-weight: 400;
  letter-spacing: calc(100vh * 0 / 375);
  line-height: calc(100vh * 12 / 375);
  color: rgba(204, 204, 204, 1);
  text-align: end;
  vertical-align: top;
}
.right_top_title_en_page_2_turn{

  opacity: 1;
  text-shadow: calc(100vw * 0 / 375) calc(100vw * 1 / 375) calc(100vw * 2 / 375)  rgba(0, 0, 0, 0.5);
  /** 文本1 */
  font-size: calc(100vh * 8 / 667);
  font-weight: 400;
  letter-spacing: calc(100vw * 0 / 375);
  line-height: calc(100vw * 12 / 375);
  color: rgba(204, 204, 204, 1);
  text-align: end;
  vertical-align: top;
}
.right_top_title_zh_page_2{

  opacity: 1;
  text-shadow: 0px 1px 2px  rgba(0, 0, 0, 0.8);
  /** 文本1 */
  font-size: calc(100vw *16/ 667);
  font-weight: 500;
  letter-spacing: calc(100vh * 0 / 375);
  line-height: calc(100vh *18 / 375);
  color: rgba(255, 255, 255, 1);
  text-align: end;
  vertical-align: top;
}
.right_top_title_zh_page_2_turn{

  height: calc(100vw * 16 / 375);
  opacity: 1;
  text-shadow: 0px 1px 2px  rgba(0, 0, 0, 0.8);
  /** 文本1 */
  font-size: calc(100vh *16/ 667);
  font-weight: 500;
  letter-spacing: calc(100vw * 0 / 375);
  line-height: calc(100vw *17 / 375);
  color: rgba(255, 255, 255, 1);
  text-align: end;
  vertical-align: top;
}
.right_top_page_2{
  display: flex;
  justify-content: flex-end;
  // padding-right: calc(100vh * 20 / 375);
  // padding-top: calc(100vh * 20 / 375);
  align-items: center;
  z-index: 4;
}
.right_top_page_2_turn{
  display: flex;
  justify-content: flex-end;
  // padding-right: calc(100vw * 20 / 375);
  // padding-top: calc(100vw* 20 / 375);
  align-items: center;
  z-index: 4;
}
.left_top_en{
  font-size: calc(100vw * 8 / 667);
  font-weight: 400;
  letter-spacing: calc(100vh * 0.5 / 375);
  line-height: calc(100vh * 11.58 / 375);
  color: rgba(77, 85, 255, 1);
  text-align: justify;
  vertical-align: top;

}
.left_top_en_turn{
  font-size: calc(100vh * 8 / 667);
  font-weight: 400;
  letter-spacing: calc(100vw * 0.5 / 375);
  line-height: calc(100vw * 11.58 / 375);
  color: rgba(77, 85, 255, 1);
  text-align: justify;
  vertical-align: top;

}
.left_top_zh_1{
  font-size: calc(100vw *16 / 667);
  font-weight: 500;
  letter-spacing: calc(100vh * 0 / 375);
  line-height: calc(100vh * 23.17 / 375);
  color: rgba(255, 255, 255, 1);
}
.left_top_zh_1_turn{
  font-size: calc(100vh *16 / 667);
  font-weight: 500;
  letter-spacing: calc(100vw * 0 / 375);
  line-height: calc(100vw * 23.17 / 375);
  color: rgba(255, 255, 255, 1);
}
.left_top_zh_2{
  font-family: '思源宋体 Bold';
  font-size: calc(100vw * 28 / 667);
  font-weight: 700;
  letter-spacing: calc(100vh *2 / 375);
  line-height: calc(100vh * 32/ 375);
  padding-bottom: calc(100vh * 8/ 375);
  color: rgba(255, 255, 255, 1);
  z-index: 2;
}
.left_top_zh_2_turn{
  font-family: '思源宋体 Bold';
  font-size: calc(100vh * 28 / 667);
  font-weight: 700;
  letter-spacing: calc(100vw *2 / 375);
  line-height: calc(100vw * 32/ 375);
  padding-bottom: calc(100vw * 8/ 375);
  color: rgba(255, 255, 255, 1);
  z-index: 2;
}
.mask{
  position:fixed;
  top     : 0;
  left    : 0;
  bottom  : 0;
  right   : 0;
  background:red;  /*一般遮罩随便设置一个颜色*/
}
.company_info,.company_info_turn{
  overflow-x: hidden;
  overflow-y: hidden;
  .scroll::-webkit-scrollbar{//隐藏或取消滚动条
    display: none;
  }
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.company_info_turn::-webkit-scrollbar {
  display: none;
}
.company_info .company_info_text_2{
  font-size:calc(100vw * 22 / 1920);
  font-weight: 400;
  line-height: calc(100vw * 42 / 1920);
  color: rgba(204, 204, 204, 1);
  margin-bottom: calc(100vh * 20/ 375);
}

.company_info .company_info_text_2_strong,.company_info_turn .company_info_text_2_strong{
  font-weight: 700;
  color: rgba(255, 255, 255, 1)
}


.company_info .company_info_text_4_strong,.company_info_turn .company_info_text_4_strong{
  font-weight: 700;
  color: rgba(255, 255, 255, 1)
}


.company_info_turn .company_info_text_2_turn{
  font-size: calc(100vw * 12 / 375);
  line-height: calc(100vw * 20 / 375);
  padding-bottom: calc(100vw * 8/ 375);
  color:rgba(204, 204, 204, 1);
  text-align: justify;
}

.company_info_turn .company_info_text_4_turn{
  font-family: "Arial Black";
  font-size: calc(100vw * 10 / 375) ;
  color:rgba(204, 204, 204, 1);
  zoom: 0.75;
  line-height: calc(100vw * 13 / 375);
  text-align: justify;
}

.button_title_en_page_1{
  font-size: calc(100vw * 8 / 667);
  font-weight: 400;
  letter-spacing: calc(100vh * 0.5 / 375);
  line-height: calc(100vh * 11 / 375);
  color: rgba(204, 204, 204, 1);
  text-align: left;
  vertical-align: top;
  writing-mode:vertical-rl;
}
.button_title_en_page_1_turn{
  font-size: calc(100vh * 8 / 667);
  font-weight: 400;
  letter-spacing: calc(100vw * 0.5 / 375);
  line-height: calc(100vw * 11 / 375);
  color: rgba(204, 204, 204, 1);
  text-align: left;
  vertical-align: top;
  writing-mode:vertical-rl;
}
.right_team_members,.right_team_members_turn{
  position: fixed;
  right: 0;
}
.right_team_members{
  bottom: calc(100vh * 20 / 375);
}
.right_team_members_turn{
  bottom: calc(100vw * 20 / 375);

}
.button_title_zh_page_1{
  writing-mode:vertical-rl;
  font-size: calc(100vw * 12 / 667);
  font-weight: 400;
  letter-spacing: calc(100vh * 0 / 375);
  line-height: calc(100vh *16.2 / 375);
  color: rgba(255, 255, 255, 1);
  text-align: left;
  vertical-align: top;
}
.button_title_zh_page_1_turn{
  writing-mode:vertical-rl;
  font-size: calc(100vh * 12 / 667);
  font-weight: 400;
  letter-spacing: calc(100vw * 0 / 375);
  line-height: calc(100vw *16.2 / 375);
  color: rgba(255, 255, 255, 1);
  text-align: left;
  vertical-align: top;
}
.case_title_en{
  font-family: '思源黑体 Normal';
  font-size: calc(100vw * 5.5 / 667);
  font-weight: 400;
  letter-spacing:calc(100vh *0 / 375);
  line-height: calc(100vh * 9 / 375);
  color: rgba(204, 204, 204, 1);
  text-align: left;
  vertical-align: middle;
}
.case_block{
  margin-top: calc(100vh * 5 / 375);
  padding-left:calc(100vw * 10 / 667) ;
  height: calc(100vh * 155 / 375);
}
.case_block_turn{
  margin-top: calc(100vw * 5 / 375);
  // padding: calc(100vw * 10 / 375);
  padding-left:calc(100vh * 10 / 667) ;
  height: calc(100vw * 150 / 375);
}
.case_title_en_turn{
  font-family: '思源黑体 Normal';
  font-size: calc(100vh * 22/ 1920);
  font-weight: 500;
  line-height: calc(100vw * 9 / 375);
  color: rgba(204, 204, 204, 1);
  text-align: left;
  vertical-align: middle;
  zoom:0.8;
}
.caselist_title_turn{
  font-family: '思源黑体 Normal';
  font-size: calc(100vh * 12 / 667);
  font-weight: 500;
  line-height: calc(100vh * 18 / 667);
  color: rgba(255, 255, 255, 1);
  text-align: left;
  vertical-align: middle;
}
.caselist_title{
  font-family: '思源黑体 Normal';
  font-size: calc(100vw * 12 / 667);
  font-weight: 500;
  line-height: calc(100vw * 18 / 667);
  color: rgba(255, 255, 255, 1);
  text-align: left;
  vertical-align: middle;
}
.font_family_sy_balck{
  font-family: '思源黑体 Normal';
}
.case_title{
  font-family: '思源黑体 Normal';
  font-size: calc(100vw * 13 / 667);
  font-weight: 500;
  line-height: calc(100vh * 18 / 375);
  color: rgba(255, 255, 255, 1);
  text-align: left;
  //margin-top:  calc(100vh * -12 / 375);
}
.case_title_turn{
  //margin-top:  calc(100vw * -18 / 375);
  font-size: calc(100vh * 13 / 667);
  font-weight: 500;
  letter-spacing: 0px;
  line-height: calc(100vw * 15 / 375);
  color: rgba(255, 255, 255, 1);
  text-align: left;
  //vertical-align: middle;
}
.case_icon{
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.case_icon_size
{
  width: calc(100vh *48 / 375) ;
  height: calc(100vh * 22 / 375);
  margin-left: calc(-100vw * 3 / 375) ;
}
.case_icon_size_turn
{
  width: calc(100vw *45 / 375) ;
  height: calc(100vw * 22 / 375);
  margin-left: calc(-100vh * 2 / 375) ;
}
.case_info{
  font-family: '思源黑体 Normal';
  font-size: calc(100vw * 6.5 / 667);
  font-weight: 400;
  line-height: calc(100vh * 14 / 375);
  color: rgba(255, 255, 255, 1);
  text-align: left;
  vertical-align: middle;
}
.case_info_turn{
  font-family: '思源黑体 Normal';
  font-size: calc(100vh * 8.5 / 667);
  font-weight: 400;
  line-height: calc(100vw * 14 / 375);
  color: rgba(255, 255, 255, 1);
  text-align: left;
  vertical-align: middle;
}
.case_info_block{
  height: calc(100vh * 75 / 375);
}
.case_info_block_turn{
  height: calc(100vw * 75 / 375);
}
.case_catalog_block{

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
}
.catalog_bg_img_0{
  background-size:100%;

  border-image: linear-gradient(to right,  rgba(0,0,0,1)0%,rgba(39, 245, 132, 1) 3%, rgba(39, 49, 246, 1)  62.89%, rgba(29, 36, 185, 1)) 1;

}
.catalog_bg_img_1{
  background-size:100%;
  .box_shadow;
  border-image: linear-gradient(to right,  rgba(0,0,0,1)0%,rgba(226, 68, 235, 1) 3%, rgba(39, 49, 246, 1) 62.89%, rgba(29, 36, 185, 1)) 1;
}
.catalog_bg_img_2 {
  background-size: 100%;
  .box_shadow;








  .button_pointer {
    cursor: pointer;
  }

  .aside-title-zh {
    font-size: calc(100vw * 12 / 667);
    font-weight: 500;
    letter-spacing: calc(100vh * 0 / 375);
    line-height: calc(100vh * 13 / 375);
    color: rgba(255, 255, 255, 1);
    text-align: left;
    vertical-align: middle;
    writing-mode: vertical-rl;
  }
  .aside_title_zh_mobile{
    font-size: calc(100vh * 12 / 667);
    font-weight: 500;
    letter-spacing: calc(100vw * 0 / 375);
    line-height: calc(100vw * 13 / 375);
    //color: rgba(255, 255, 255, 1);
    color: red;
    text-align: left;
    vertical-align: middle;
    writing-mode: vertical-rl;
  }

  .aside-title-en{
    font-size: calc(100vw * 7 / 667);
    font-weight: 400;
    letter-spacing: calc(100vh * 0 / 375);
    line-height: calc(100vh * 15 / 375);
    color: rgba(255, 255, 255, 1);
    text-align: left;
    vertical-align: top;
    writing-mode: vertical-rl;
  }
  .aside_title_en_mobile{
    font-size: calc(100vh * 7 / 667);
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    text-align: left;

    writing-mode: vertical-rl;
  }
  .flex_row_space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .flex_center_row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .flex_center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .flex_col_end{
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
  }

  .aside_container_flex {

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

  }

  .move_ball_ring {


    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-items: center;
  }

  .index_1_right_catalogs_info {

    font-size: calc(100vw * 10 / 667);
    font-weight: 600;
    letter-spacing: calc(100vh * 1 / 375);
    line-height: calc(100vh * 10 / 375);
    color: rgba(255, 255, 255, 1);
    text-align: left;
    vertical-align: middle;
    margin-bottom: calc(100vh * 5 / 375);
  }
  .index_1_right_catalogs_info_turn {

    font-size: calc(100vh * 10 / 667);
    font-weight: 600;
    letter-spacing: calc(100vw * 1 / 375);
    line-height: calc(100vw * 10 / 375);
    color: rgba(255, 255, 255, 1);
    text-align: left;
    vertical-align: middle;
    margin-bottom: calc(100vw * 5 / 375);
  }
  .company_info_text_1 {
    margin-top: calc(100vh * 10 / 375);
    ffont-size: 10px;
    font-weight: 700;
    letter-spacing: calc(100vh * 0 / 375);
    line-height: calc(100vh * 20 / 375);
    color: rgba(255, 255, 255, 1);
  }
  .company_info_text_1_turn {
    margin-top: calc(100vw * 10 / 375);
    font-size: 6px;
    font-weight: 700;
    letter-spacing: calc(100vw * 0 / 375);
    line-height: calc(100vw * 20 / 375);
    color: rgba(255, 255, 255, 1);
  }

  //.company_info_text_2 {
  //  font-size: calc(100vw * 8 / 667);
  //  font-weight: 400;
  //  letter-spacing: calc(100vh * 0 / 375);
  //  line-height: calc(100vh * 15 / 375);
  //  color: rgba(204, 204, 204, 1);
  //}
  //.company_info_text_2_turn {
  //  font-size: calc(100vw * 26 / 375);
  //  font-weight: 400;
  //  letter-spacing: calc(100vw * 0 / 375);
  //  line-height: calc(100vw * 18 / 375);
  //  color: rgba(204, 204, 204, 1);
  //}


  .company_info .company_info_text_4{
    font-family: '思源黑体 Normal';
    font-size: calc(100vh * 8 / 375);
    color: rgba(204, 204, 204, 1);
    text-align: justify;
  }
}
.word-break{
  word-break: break-all;

}
.company_info_en_mobile{
  font-family: '思源黑体 Normal';
  font-size: calc(100vh * 20 /1920);
  line-height: calc(100vh * 34 /1920);
  color: rgba(204, 204, 204, 1);
  text-align: justify;

}
.company_info_en_web{
  font-family: '思源黑体 Normal';
  font-size: calc(100vw * 18 /1920);
  line-height: calc(100vw * 24 /1920);
  color: rgba(204, 204, 204, 1);
  text-align: justify;
  //word-break: break-all;
}
.catalog_bg_img_2 {
  background-size: 100%;
  .box_shadow;
  border-image: linear-gradient(to right, rgba(0,0,0,1)0%, rgba(250, 81, 42, 1) 3%, rgba(39, 49, 246, 1) 62.89%, rgba(29, 36, 185, 1)) 1;
}
.catalog_bg_img_3 {
  background-size: 100%;
  .box_shadow;
  border-image: linear-gradient(to right, rgba(0,0,0,1)0%,rgba(42, 130, 228, 1) 3%, rgba(39, 49, 246, 1) 62.89%, rgba(29, 36, 185, 1)) 1;
}
.catalog_bg_img_4 {
  background-size: 100%;
  .box_shadow;
  border-image: linear-gradient(to right,  rgba(0,0,0,1)0%,rgba(121, 72, 234, 1) 3%, rgba(39, 49, 246, 1) 62.89%, rgba(29, 36, 185, 1)) 1;
}
.catalog_bg_img_5 {
  background-size: 100%;
  .box_shadow;
  border-image: linear-gradient(to right,  rgba(0,0,0,1)0%,rgba(0, 231, 235, 1) 3%, rgba(39, 49, 246, 1) 62.89%, rgba(29, 36, 185, 1)) 1;
}
.index_1_whole_padding{
  padding-left: calc(100vh * 30 / 375);
  padding-right: calc(100vh * 30 / 375);
  padding-bottom: calc(100vh * 25 / 375);
  padding-top: calc(100vh * 25 / 375);
}
.index_1_whole_padding_turn{
  padding-left: calc(100vw * 30 / 375);
  padding-right: calc(100vw * 30 / 375);
  padding-bottom: calc(100vw * 25 / 375);
  padding-top: calc(100vw * 25 / 375);
}
.top_right_logo{
  width: calc(100vh * 18 / 375);
  height: calc(100vh * 27/ 375);
  padding-left: calc(100vw * 8/ 667);
}
.top_right_logo_turn{
  width: calc(100vw * 18 / 375);
  height: calc(100vw * 27/ 375);
  padding-left: calc(100vh * 8/ 667);
}
.index_1_ball{
  // margin-top: calc(100vh * 30 / 375);
}
.index_1_ball_turn{
  // margin-top: calc(100vw * 30 / 375);
}
.index_2_whole_padding{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: calc(100vh * 25 / 375);
  padding-bottom: calc(100vh * 25 / 375);
  padding-left: calc(100vh * 30 / 375);
  padding-right: calc(100vh * 30 / 375);
}
.index_2_whole_padding_turn{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: calc(100vw * 25 / 375);
  padding-bottom: calc(100vw * 25 / 375);
  padding-left: calc(100vw * 30 / 375);
  padding-right: calc(100vw * 30 / 375);
}
.aside_title_part,.aside_title_part_turn{
  -webkit-writing-mode: vertical-rl;
  text-align:start;
}
.aside_title_part{
  margin-top: calc(100vw * 45/1920);
  padding-top:  calc(100vw * 23 /1920);
}
.aside_title_part_turn{
  margin-top: calc(100vh * 45 /1920);
  padding-top:  calc(100vh * 23 /1920);
}
.aside_main_title{
  font-size: calc(100vw * 12/ 667);
}
.aside_main_title_turn{
  font-size: calc(100vh * 14/ 667);
}
.aside_en_title{
  font-size: calc(100vw * 6/ 667);
}
.aside_en_title_turn{
  font-size: calc(100vh * 7/ 667);
}
.case_refaresh_button{
  margin-bottom: calc(100vh * 40/ 375);
  margin-top: calc(100vh * 25/ 375);
}
.case_refaresh_button_turn{
  margin-bottom: calc(100vw * 35/ 375);
  margin-top: calc(100vw * 20/ 375);
}
.case_button_text,.case_button_text_turn{
  display: flex;
  flex-direction: column;
}
.case_button_text{
  color: white;
  font-size: calc(100vw * 5/ 667);
}
.case_button_text_turn{
  color: white;
  font-size: calc(100vh * 6/ 667);
  zoom: 0.9;
}
.loading_part{
  font-family: '思源黑体 Normal';
  background-color:black;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loading_part_img{
  width: calc(100vw * 90 / 667);
  height: calc(100vw * 90 / 667);
  margin-top: calc(100vh * 50 / 375);
  margin-bottom: calc(100vh * 10 / 375);
}
.loading_part_text{
  color: rgba(32, 39, 201, 1);
  font-size: calc(100vw * 20/ 667);
  font-weight: 700;
}
.case_left_btn{
  position:fixed;
  left: calc(100vw * 90 / 667);
  bottom: calc(100vh * 25 / 375);
}
.case_right_btn{
  position:fixed;
  right:calc(100vw * 30 / 667);
  bottom: calc(100vh * 25 / 375);
}
.index_3_whole{
  display: flex;justify-content: space-between;flex-direction: column;
  padding-top: calc(100vh * 25 / 375);
  padding-bottom: calc(100vh * 25 / 375);
  padding-left: calc(100vh * 30 / 375);
  padding-right: calc(100vh * 30 / 375);
}
.index_3_whole_turn{
  display: flex;justify-content: space-between;flex-direction: column;
  padding-top: calc(100vw * 25 / 375);
  padding-bottom: calc(100vw * 25 / 375);
  padding-left: calc(100vw * 30 / 375);
  padding-right: calc(100vw * 30 / 375);
}
.case_more{
  width: calc(100vh * 80 / 375);
  height: calc(100vh * 20 / 375);
}
.case_more_turn{
  width: calc(100vw * 80 / 375);
  height: calc(100vw * 20 / 375);
}

.team_introduction_part{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  width: calc(100vw*600/1920);
}
.team_introduction_part_turn{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: calc(100vh*580/1920);
}
.team_introduction_part_1{
  margin-top: calc(100vh * 8 / 375);
  margin-bottom: calc(100vh * 10 / 375);
  font-size: calc(100vw * 8 / 667);
  font-weight: 400;
  letter-spacing: calc(100vh * 0 / 375);
  line-height: calc(100vh * 15 / 375);
  color: rgba(204, 204, 204, 1);
}
.team_introduction_part_1_turn{

  font-size: calc(100vw * 12 / 375);
  line-height: calc(100vw * 20 / 375);
  padding-bottom: calc(100vw * 8/ 375);
  color:rgba(204, 204, 204, 1);
  text-align: justify;
}
.team_introduction_part_2{
  font-family: '思源黑体 Normal';
  font-size: calc(100vh * 8 / 375);
  color: rgba(204, 204, 204, 1);
  text-align: justify;
}
.team_introduction_part_2_turn{
  font-family: '思源黑体 Normal';
  font-size: calc(100vw * 10 / 375) ;
  color:rgba(204, 204, 204, 1);
  zoom: 0.75;
  line-height: calc(100vw * 13 / 375);
  text-align: justify;
}
.service_text{
  display: flex;
  font-size: calc(100vh * 10 / 375);
  line-height: calc(100vh * 20 / 375);
  color: rgba(204, 204, 204, 1);
}
.service_text_turn{
  display: flex;
  font-size: calc(100vw * 12 / 375);
  line-height: calc(100vw * 20 / 375);
  color: rgba(204, 204, 204, 1);
}
.service_text_margin_bt_turn{
  margin-bottom: calc(100vh * 36 / 1920);
}
.service_text_margin_bt{
  margin-bottom: calc(100vw * 36 / 1920);
}

.margin_button_top
{
  margin-top:  calc(100vw * 25 / 1920);
}
.margin_button_top_turn
{
  margin-top:  calc(100vh * 25 / 1920);
}
.en_font_black{
  font-family: '思源黑体 Normal';
}
.box_shadow{
  box-shadow: inset 4px 0px 4px  rgba(0,0,0,1);
}
.box_shadow_web{
  box-shadow: inset calc(100vw *12 / 1920)  0px calc(100vw *12 / 1920)  rgba(0,0,0,1);
}
.service_box{
  background: rgba(46,46,46,1);
  //.container_border_debug;
  // border: 0.5px solid rgba(0, 0, 0, 1);

  border-bottom: 6px solid transparent;
  border-width: calc(100vh *2.2 / 375);
}
.index_3_whole_turn .service_box{
  border-width: calc(100vw *2 / 375);
}
.case_title_part{
  display: flex;
  justify-content: left;
}
.case_arrow{
  width: calc(100vh*6/375);
  padding-left: calc(100vw*3/375);
  padding-top: calc(100vh*4/375);
}
.case_arrow_turn{
  width: calc(100vw*6/375);
  padding-left: calc(100vh*3/375);
  padding-top: calc(100vw*4/375);
}
.case_title_grad{
  font-family: '思源黑体 Normal';

  font-weight: 500;
  text-transform:uppercase;
  background-clip: text;
  -webkit-background-clip: text;
  //background-image: linear-gradient(to right, pink, purple);
  color: transparent;
  -webkit-background-clip: text;
}
.case_left_title_1_turn{
  font-size: calc(100vh * 52 /1920);
  line-height: calc(100vh * 75 /1920);
  //margin-top: calc(100vh * 20 /1920);
}
.case_left_title_1{
  font-size: calc(100vw * 52 /1920);
  line-height: calc(100vw * 75 /1920);
  //margin-top: calc(100vw * 20 /1920);
}
.case_left_title_2_turn{
  font-size: calc(100vh * 52 /1920);
  line-height: calc(100vh * 75 /1920);
  margin-bottom: calc(100vh * 25 /1920);
}
.case_left_title_2{
  font-size: calc(100vw * 52 /1920);
  line-height: calc(100vw * 75 /1920);
  margin-bottom: calc(100vw * 25 /1920);
}
.case_grad_color_0{
  background-image: linear-gradient(to right, hsla(238,73%,46%,1),hsla(150,81%,60%,1));
}
.case_grad_color_1{
  background-image: linear-gradient(to right, hsla(238,73%,46%,1),hsla(297,81%,59%,1));
}
.case_grad_color_2{
  background-image: linear-gradient(to right, hsla(238,73%,46%,1),hsla(11,95%,57%,1));
}
.case_grad_color_3{
  background-image: linear-gradient(to right, hsla(238,73%,46%,1),hsla(193,93%,56%,1));
}
.case_grad_color_4{
  background-image: linear-gradient(to right,hsla(238,73%,46%,1),hsla(258,79%,60%,1));
}
.case_grad_color_5{
  //border-image: linear-gradient(to right,  ,rgba(0, 231, 235, 1) 3%, rgba(39, 49, 246, 1) 62.89%, rgba(29, 36, 185, 1)) 1;
  background-image: linear-gradient(to right,hsla(238,73%,46%,1),hsla(185,100%,49.6%,1));
  //background-image: linear-gradient(to right,hsla(238,73%,46%,1),hsla(297,81%,59%,1));
}
.case_grad_color_0_bottom{
  background-image: linear-gradient(to bottom, hsla(238,73%,46%,1),hsla(150,81%,60%,1));
}
.case_grad_color_1_bottom{
  background-image: linear-gradient(to bottom, hsla(238,73%,46%,1),hsla(297,81%,59%,1));
}
.case_grad_color_2_bottom{
  background-image: linear-gradient(to bottom, hsla(238,73%,46%,1),hsla(11,95%,57%,1));
}
.case_grad_color_3_bottom{
  background-image: linear-gradient(to bottom, hsla(238,73%,46%,1),hsla(193,93%,56%,1));
}
.case_grad_color_4_bottom{
  background-image: linear-gradient(to bottom,hsla(238,73%,46%,1),hsla(258,79%,60%,1));
}
.case_grad_color_5_bottom{
  background-image: linear-gradient(to bottom,hsla(238,73%,46%,1),hsla(185,100%,49.6%,1));

}
.case_info_line_turn{
  font-family: '思源黑体 Normal';
  font-size: calc(100vh * 20 /1920);
  //line-height: calc(100vh * 29 /1920);
  color: rgba(255, 255, 255, 0.5);
}
.case_info_line{
  font-family: '思源黑体 Normal';
  font-size: calc(100vw * 20 /1920);
  //line-height: calc(100vw * 29 /1920);
  color: rgba(255, 255, 255, 0.5);
}
.case_title_turn{
  padding-left:calc(100vh * 25 /1920);
  text-align: left;
}
.case_detail_padding_turn{
  padding-top:calc(100vw * 60 /1080);
  padding-bottom:calc(100vw * 60 /1080);
}
.case_detail_padding{
  padding-top:calc(100vh * 60 /1080);
  padding-bottom:calc(100vh * 60 /1080);
}
.case_title{
  padding-left:calc(100vw * 25 /1920);
  text-align: left;
}
.case_catalog_left_logo_turn{
  justify-content: right;
  margin-left: calc(100vh * 180 /1920);
  margin-bottom: calc(100vh * 35 /1920);
}
.case_catalog_left_logo{
  justify-content: right;
  margin-left:calc(100vw * 180 /1920);
  margin-bottom: calc(100vw * 45 /1920);
}


.case_6_card_title_turn{
  font-size:calc(100vh * 22 /1920);
  line-height: calc(100vh * 26 /1920);
  margin-left: calc(100vh * 18 /1920);
  margin-right:calc(100vh * 18 /1920);
  //overflow-x: hidden;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  vertical-align: top;
}
.case_6_card_title{
  font-size:calc(100vw * 22 /1920);
  line-height: calc(100vw * 24 /1920);
  font-weight: 400;
  margin-left: calc(100vw * 18 /1920);
  margin-right:calc(100vw * 18 /1920);
  //overflow-x: hidden;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  vertical-align: top;
}
.case_card_text_turn{
  font-size:calc(100vh * 18 /1920);
  font-weight: 400;
  line-height: calc(100vh * 20 /1920);
  color: rgba(102, 102, 102, 1);
  margin-left: calc(100vh * 18 /1920);
  margin-bottom: calc(100vh * 8 /1920);
  text-align: left;
  vertical-align: top;
}
.case_card_text{
  font-size:calc(100vw *18 /1920);
  font-weight: 400;
  line-height: calc(100vw * 20 /1920);
  margin-left: calc(100vw * 18 /1920);
  color: rgba(102, 102, 102, 1);
  margin-bottom: calc(100vw * 8 /1920);
  text-align: left;
  vertical-align: top;
}
.case_card_title_turn{
  font-size:calc(100vh * 28 /1920);
  font-weight: 400;
  line-height: calc(100vh * 40 /1920);
  color: rgba(255, 255, 255, 1);
  text-align: left;
  vertical-align: top;
}
.case_card_title{
  font-size:calc(100vw * 22 /1920);
  font-weight: 400;
  line-height: calc(100vw * 40 /1920);
  color: rgba(255, 255, 255, 1);
  text-align: left;
  vertical-align: top;
}
.case_card_bottom_mar_turn{
  margin:calc(100vh * 20 /1920);
}
.case_card_bottom_mar{
  margin-bottom:calc(100vw * 50 /1920);
}
.case_card{
  border-radius:  calc(100vh * 12 /1080)   calc(100vh * 12 /1080)  calc(100vh * 12 /1080)  calc(100vh * 12 /1080);
  //border-radius:  calc(100vh * 12 /1920)  0px  12px  12px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.case_card_main_img{
  border-radius: 0 0 calc(100vh * 12 /1080)  calc(100vh * 12 /1080);
  //border-radius: calc(100vh * 12 /1920);
  //border: 1px solid rgba(255, 255, 255, 0.2);
  //border-color: red;
}
/deep/.el-scrollbar__wrap {
  overflow-x: hidden;//将横向滚动条隐藏
}
/deep/.el-scrollbar__thumb {
  //可设置滚动条颜色
  background: transparent;//这里我设置成了透明色,可以根据需求添加自己想要的颜色
}

.detail_close_icon{
  height: calc(100vw * 27 /1920);
}
.detail_close_icon_turn{
  height: calc(100vh * 27 /1920);
}
.detail_pic_close_icon_turn{
  height: calc(100vh * 88/1920);
}
.detail_pic_close_icon{
  height: calc(100vw * 66 /1920);
}
.detail_info_text{
  font-size: calc(100vw * 26/1920);;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  text-align: center;
  vertical-align: top;
}
.detail_info_text_turn{
  font-size: calc(100vh * 26/1920);;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  text-align: center;
  vertical-align: top;
}
.carousel_index_bar_turn
{
  height: calc(100vh * 5 /1920);
}
.carousel_index_bar
{
  height: calc(100vw * 5 /1920);
}
.waterfull{
  width: 100%;
}
.v-waterfall-content {
  /* 主要 */
  height: 100%;
  width: 100%;
  position: relative;
  /* 次要：设置滚动条，要求固定高度 */
  //overflow-y: auto;
}

.v-waterfall-item {
  /* 主要 */
  float: left;
  position: absolute;
  //margin-left: calc(100vh * 20 /1920);
  //margin-bottom: calc(100vh * 40 /1920);
}

.v-waterfall-item img {
  /* 主要 */
  /* width: auto;height: auto; */

  width: 100%;
  //height: auto;
  /* 次要 */
  border-radius: 2px;
}
/deep/.el-scrollbar__wrap {
  overflow-x: hidden;//将横向滚动条隐藏
}
/deep/.el-scrollbar__thumb {
  //可设置滚动条颜色
  background: transparent;//这里我设置成了透明色,可以根据需求添加自己想要的颜色
}
.card_title_turn{
  font-family: '思源黑体 Normal';
  font-size: calc(100vh * 24 / 1920);
  font-weight: 400;
  letter-spacing: 0px;
  line-height:  calc(100vh * 40 / 1920);
  color: rgba(255, 255, 255, 1);
  text-align: left;
  vertical-align: center;
  ext-overflow:ellipsis;
}
.card_title{
  font-family: '思源黑体 Normal';
  font-size: calc(100vw * 28 / 1920);
  font-weight: 400;
  letter-spacing: 0px;
  line-height:  calc(100vw * 40 / 1920);

  color: rgba(255, 255, 255, 1);
  text-align: left;
  vertical-align: top;
  ext-overflow:ellipsis;
}
.case_card_icon{
  height: calc(100vw * 21 / 1920);
}
.case_card_icon_turn{
  height: calc(100vh * 21 / 1920);
}
.card_icon_bar{
  width: calc(100vw * 100 / 1920);
  height: calc(100vw *50 / 1920);
}
.card_icon_bar_turn{
  width: calc(100vh * 100 / 1920);
  height: calc(100vh * 50 / 1920);
}
.card_icon_bar2_turn{
  width: calc(100vh * 40 / 1920);
  height: calc(100vh * 50 / 1920);
}
.card_icon_bar2{
  width: calc(100vw * 40 / 1920);
  height: calc(100vw * 50 / 1920);
}

.waterfall_scroll_inter_turn{
  width: calc(100vh * 1280 / 1920);
  height: calc(100vh * 1028 / 1920);
}
.waterfall_scroll_inter{
  width: calc(100vw * 1280 / 1920);
  height: calc(100vw * 1028 / 1920);
}
.debug_border{
  border: 1px solid #00ff6f;
}
</style>
